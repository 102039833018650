import React from "react";
import logo from "../assets/logoany.png";
import "./footer.css";

import { FaDiscord } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaYoutube } from "react-icons/fa";
import { FaGithub } from "react-icons/fa6";

import { IoLocationSharp } from "react-icons/io5";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { MdMail } from "react-icons/md";

function Footer() {
  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div
      style={{ backgroundColor: "#E2CAFF", position: "relative", zIndex: "1" }}
    >
      <div className="container footer">
        <div className="row">
          <div className="mb-5 col-12 col-sm-12 col-md-12 col-lg d-flex align-items-center justify-content-center">
            <img src={logo} className=" logof" />
          </div>
          <div className="mb-5 col-6 col-sm-6 col-md-6 col-lg">
            <div>
              <p className="pointer mb-2 title-f">Home</p>
              <p className="pointer mb-1" onClick={() => scrollToSection("s1")}>
                Cover Page
              </p>
              <p className="pointer mb-1" onClick={() => scrollToSection("s2")}>
                List Ecosystem Project
              </p>
              <p className="pointer mb-1" onClick={() => scrollToSection("s3")}>
                De-Fi Feature
              </p>
              <p className="pointer mb-1" onClick={() => scrollToSection("s4")}>
                Real Estate Ecosystem
              </p>
              <p className="pointer mb-1" onClick={() => scrollToSection("s5")}>
                Platform Roadmap
              </p>
              <p className="pointer mb-1" onClick={() => scrollToSection("s5")}>
                Whitelist Project
              </p>
            </div>
          </div>
          <div className="mb-5 col-6 col-sm-6 col-md-6 col-lg">
            <div>
              <p className="pointer mb-2 title-f">Ecosystem</p>
              <p className="pointer mb-1">Explanation</p>
              <p className="pointer mb-1">Illustration</p>
              <p className="pointer mb-1">Project List</p>
            </div>
          </div>
          <div className="mb-5 col-12 col-sm-6 col-md-6 col-lg">
            <div>
              <p className="mb-2 title-f">Social Media</p>
              <div className="row mt-2 me-4">
                <div className="col-3 col-md-3 col-lg-4 col-xl-3 mb-3">
                  <div className="sm">
                    <FaDiscord color="white" size={20} />
                  </div>
                </div>
                <div className="col-3 col-md-3 col-lg-4 col-xl-3 mb-3">
                  <div className="sm">
                    <FaXTwitter color="white" size={20} />
                  </div>
                </div>
                <div className="col-3 col-md-3 col-lg-4 col-xl-3 mb-3">
                  <div className="sm">
                    <FaYoutube color="white" size={20} />
                  </div>
                </div>
                <div className="col-3 col-md-3 col-lg-4 col-xl-3 mb-3">
                  <div className="sm">
                    <FaGithub color="white" size={20} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mb-5 col-12 col-sm-6 col-md-6 col-lg">
            <div>
              <div className="d-flex align-items-start mb-2">
                <IoLocationSharp
                  style={{ width: "10%" }}
                  size={20}
                  className="me-2"
                  color="#4f3fff"
                />
                <p className="mb-0 w-100">
                  Graha Mampang Lt.3 Suite 305, Jl. Mampang Prapatan Kav.100,
                  Jakarta Selatan 12760
                </p>
              </div>
              <div className="d-flex align-items-center mb-2">
                <BsFillTelephoneForwardFill
                  style={{ width: "10%" }}
                  size={16}
                  className="me-2"
                  color="#4f3fff"
                />

                <p className="mb-0 w-100">+62-123-456-789</p>
              </div>
              <div className="d-flex align-items-center mb-2">
                <MdMail
                  style={{ width: "10%" }}
                  size={20}
                  className="me-2"
                  color="#4f3fff"
                />
                <p className="mb-0 w-100">official@zodiaxp.io</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
