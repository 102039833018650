import React, { useState, useEffect, useRef } from "react";
import Navbar from "../../component/Navbar";
import "./ecosystem.css";
import arrow from "../../assets/arrow.json";
import Lottie from "lottie-web";

import img1 from "../../assets/eco-s21.png";
import img2 from "../../assets/eco-s22.png";
import img3 from "../../assets/eco-s23.png";

import imgbg from "../../assets/eco-img-bg.png";

import img1bg from "../../assets/s31e.png";
import img2bg from "../../assets/s32e.png";
import img3bg from "../../assets/s33e.png";
import img4bg from "../../assets/s34e.png";
import Footer from "../../component/Footer";
import Loading from "../../component/Loading";
import Particle from "../../component/Particle";
import { useNavigate } from "react-router-dom";

function Ecosystem() {
  const navigate = useNavigate();

  const [fade, setFade] = useState(true);
  const assets = [img1, img2, img3, imgbg, img1bg, img2bg, img3bg, img4bg];
  useEffect(() => {
    const loadAssets = async () => {
      const images = assets.map((item) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = item;
          img.onload = () => resolve(item);
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(images);
        setTimeout(() => {
          setFade(false); // Start the fade-out transition
        }, 1000);
      } catch (error) {
        console.error("Failed to load images", error);
      }
    };

    loadAssets();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const handleScroll1 = () => {
      const section = document.getElementById("s1");
      const animatedElements = section.querySelectorAll(".animated-bot-top");

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll1();

    const handleScroll3 = () => {
      const section = document.getElementById("s3");
      const animatedElements = section.querySelectorAll(
        ".animated-right-left, .animated-left-right, .animated-bot-top",
      );

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll3();
  }, []);

  const containerRef = useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: arrow, // Lottie animation data
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Navbar activePage={"Ecosystem"} />
      <Loading end={fade} />
      <div className="section-1-eco" id="s1">
        <div className="container">
          <div
            className="d-flex flex-column align-items-center justify-content-between"
            style={{ minHeight: "70vh" }}
          >
            <p
              className="text-s1-eco animated-bot-top"
              style={{ marginTop: "120px" }}
            >
              How Important Is It ??
            </p>
            <div
              className="animated-bot-top"
              style={{ width: "200px", position: "relative", bottom: "-5vh" }}
              ref={containerRef}
            ></div>
          </div>
        </div>
      </div>
      {/* -------------------------------------------------------------------------------------------------- */}
      <div className="section-2-eco" id="s2">
        <div
          className="container d-flex align-items-center"
          style={{ minHeight: "80vh" }}
        >
          <div className="w-100">
            <div className="row" style={{ position: "relative", zIndex: "1" }}>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                <div className="card-eco-1">
                  <div className="card-inner">
                    <div className="card-front">
                      <div className="cardin">
                        <button className="btn-eco-s2 ">Tokenization</button>
                        <img src={img1} className="w-100 img-s2-eco" />
                      </div>
                    </div>
                    <div className="card-back">
                      <div className="cardin">
                        <p className="text-back">
                          At our ecosystem, we simplify the process for real
                          estate project owners to secure funding for their
                          developments through tokenization. By leveraging the
                          power of blockchain technology, we ensure unparalleled
                          transparency in transactions, providing robust
                          security for our investors. Token holders will receive
                          dividends at regular intervals, making this a
                          lucrative and reliable investment opportunity.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                <div className="card-eco-1">
                  <div className="card-inner">
                    <div className="card-front">
                      <div className="cardin">
                        <button className="btn-eco-s2">Blockchain Side</button>
                        <img src={img2} className="w-100 img-s2-eco" />
                      </div>
                    </div>
                    <div className="card-back">
                      <div className="cardin">
                        <p className="text-back">
                          Imagine a world where real estate investment is
                          accessible, transparent, and rewarding. Our platform
                          transforms this vision into reality by utilizing
                          blockchain to tokenize property assets. This
                          innovative approach allows project owners to attract
                          funding efficiently while offering investors a secure
                          and transparent way to diversify their portfolios.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-6 col-lg-4 mb-4">
                <div className="card-eco-1">
                  <div className="card-inner">
                    <div className="card-front">
                      <div className="cardin">
                        <button className="btn-eco-s2">Holders</button>
                        <img src={img3} className="w-100 img-s2-eco" />
                      </div>
                    </div>
                    <div className="card-back">
                      <div className="cardin">
                        <p className="text-back">
                          Token holders benefit from periodic dividends,
                          ensuring a steady income stream and a share in the
                          success of groundbreaking real estate projects. With
                          our cutting-edge solution, we bridge the gap between
                          traditional real estate and modern technology,
                          creating a dynamic ecosystem that fosters trust,
                          growth, and financial prosperity for all participants.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={imgbg} className="img-bg" />
        <img src={imgbg} className="img-bg2" />

        <div className="cbg"></div>
        <div className="cbg2"></div>
      </div>
      {/* -------------------------------------------------------------------------------------------------- */}
      <div className="section-3-eco" id="s3">
        <div className="container">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <p className="text-s1-eco animated-bot-top mb-3">
              Funding Solution
            </p>
            <p
              className="text-light text-center animated-bot-top"
              style={{ maxWidth: "500px" }}
            >
              Our funding solution offers unmatched advantages: transparency,
              low fees, cost and time reduction, fractional ownership, increased
              liquidity, and secure user data. Embrace a smarter way to invest
              in real estate with our innovative and efficient platform.
            </p>
            <br />
            <br />
            <button
              onClick={() => navigate(`/under-construction`)}
              className="btn-s1 animated-bot-top"
            >
              Explore
            </button>
          </div>
        </div>
        <img src={img1bg} className="bgs41" />
        <img src={img2bg} className="bgs42" />
        <img src={img3bg} className="bgs43" />
        <img src={img4bg} className="bgs44" />
      </div>
      <Footer />
      <Particle />
    </div>
  );
}

export default Ecosystem;
