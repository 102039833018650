import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { SidebarDefi } from "../../../component/defi/SidebarDefi";
import { NavbarDefi } from "../../../component/defi/NavbarDefi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import headlineImg from "../../../assets/headline.png";

import "./style.css";
import { useNavigate } from "react-router-dom";

export function News() {
  const navigate = useNavigate();
  return (
    <div>
      <SidebarDefi />

      <div className="body-dashboard">
        <NavbarDefi />
        <div className="balance-info">
          <h3>Headline</h3>
          <div>
            <div className="cardNews" onClick={() => navigate("/news-detail")}>
              <img
                src={headlineImg}
                className="rounded"
                style={{ width: "200px" }}
              />
              <div className="ms-3 w-100">
                <p className="mb-3" style={{ fontSize: "20px" }}>
                  Tokenization of Property Projects : “The Future of Investment
                  with Blockchain”
                </p>
                <div className="d-flex justify-content-between ">
                  <p
                    className="mb-0"
                    style={{ fontSize: "16px", opacity: 0.6 }}
                  >
                    1 October 2024
                  </p>
                  <p
                    className="mb-0"
                    style={{ fontSize: "16px", opacity: 0.6 }}
                  >
                    30 Minutes ago
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="cardNews">
              <img
                src={headlineImg}
                className="rounded"
                style={{ width: "200px" }}
              />
              <div className="ms-3 w-100">
                <p className="mb-3" style={{ fontSize: "20px" }}>
                  Tokenization of Property Projects : “The Future of Investment
                  with Blockchain”
                </p>
                <div className="d-flex justify-content-between ">
                  <p
                    className="mb-0"
                    style={{ fontSize: "16px", opacity: 0.6 }}
                  >
                    1 October 2024
                  </p>
                  <p
                    className="mb-0"
                    style={{ fontSize: "16px", opacity: 0.6 }}
                  >
                    30 Minutes ago
                  </p>
                </div>
              </div>
            </div>
          </div>
          <br />
          <h3>All News</h3>
          <div>
            <div className="cardNews">
              <img
                src={headlineImg}
                className="rounded"
                style={{ width: "200px" }}
              />
              <div className="ms-3 w-100">
                <p className="mb-3" style={{ fontSize: "20px" }}>
                  Tokenization of Property Projects : “The Future of Investment
                  with Blockchain”
                </p>
                <div className="d-flex justify-content-between ">
                  <p
                    className="mb-0"
                    style={{ fontSize: "16px", opacity: 0.6 }}
                  >
                    1 October 2024
                  </p>
                  <p
                    className="mb-0"
                    style={{ fontSize: "16px", opacity: 0.6 }}
                  >
                    30 Minutes ago
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
