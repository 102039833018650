import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa";

import img1 from "./assets/img1.png";

import "./dashboard.css";

import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { MdFavorite } from "react-icons/md";

const FundRaisesProgress = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="">
        <div className="d-flex w-100" style={{ padding: "20px" }}>
          <div
            className="pointer d-flex align-items-center"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack size={24} />
            <p className="mb-0 ms-2 textHead">Fund Raises Progress </p>
          </div>
        </div>
        <div className="row g-0 p-4 pb-0">
          <div className="col-12 col-sm-12 col-md-6 col-lg-6  col-xl-6 d-flex align-items-center">
            <div className="d-flex justify-content-between w-100">
              <div className="inputCO2" style={{ maxWidth: "unset" }}>
                <input
                  type="text"
                  className="inputCOinner"
                  placeholder="Type project name, project address to find your needs"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="d-flex conBtnHP ">
              <Dropdown className="my-3 me-2 w-100">
                <Dropdown.Toggle
                  className="btn-filter resL fw-semibold px-3 py-2 w-100"
                  style={{ backgroundColor: "#dadada" }}
                >
                  {"AllStatus"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="menudropdown">
                  <Dropdown.Item>Up Coming</Dropdown.Item>
                  <Dropdown.Item>On Sale</Dropdown.Item>
                  <Dropdown.Item>Ended</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="my-3 w-100">
                <Dropdown.Toggle
                  className="btn-filter resL fw-semibold px-3 py-2 w-100"
                  style={{ backgroundColor: "#dadada" }}
                >
                  {"Lock Time"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="menudropdown">
                  <Dropdown.Item disabled>No items</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown className="my-3 ms-2 w-100">
                <Dropdown.Toggle
                  className="btn-filter resL fw-semibold px-3 py-2 w-100"
                  style={{ backgroundColor: "#dadada" }}
                >
                  {"Soft Cap"}
                </Dropdown.Toggle>

                <Dropdown.Menu className="menudropdown">
                  <Dropdown.Item disabled>No items</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </div>
        <br />
        <div className="content-dashboard pt-0">
          <div className="mb-4">
            <div>
              <div className="cardInnerFR w-100 card-white p-4">
                <img
                  src={img1}
                  className="imgFR"
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "20px",
                    objectFit: "cover",
                  }}
                />
                <div className="rightFR w-100 d-flex flex-column justify-content-center ">
                  <div className="d-flex align-items-end justify-content-between mb-3">
                    <div className="flexCFR">
                      <p className="text-card-s2 mb-0">Andromeda Project</p>
                      <p className="mb-0 flexCFRtext">(Villa Sanur)</p>
                    </div>
                    <p className="text-card-s2 mb-0">90%</p>
                  </div>
                  <progress style={{ marginBottom: "8px" }} value={0.9} />{" "}
                  <div className="d-flex mt-2 align-items-center justify-content-between">
                    <button className="btn-card-lp">
                      <div className="lamp"></div>On Sale
                    </button>
                    <div className="d-flex align-items-center">
                      <MdFavorite
                        className="pointer"
                        size={24}
                        color="#FF45B4"
                      />
                      <button
                        className="btn btn-dark ms-2"
                        style={{
                          height: "36px",
                          borderRadius: "5px",
                          padding: "5px 16px",
                        }}
                      >
                        View Project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="cardInnerFR w-100 card-white p-4">
                <img
                  src={img1}
                  className="imgFR"
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "20px",
                    objectFit: "cover",
                  }}
                />
                <div className="rightFR w-100 d-flex flex-column justify-content-center ">
                  <div className="d-flex align-items-end justify-content-between mb-3">
                    <div className="flexCFR">
                      <p className="text-card-s2 mb-0">Andromeda Project</p>
                      <p className="mb-0 flexCFRtext">(Villa Sanur)</p>
                    </div>
                    <p className="text-card-s2 mb-0">90%</p>
                  </div>
                  <progress style={{ marginBottom: "8px" }} value={0.9} />{" "}
                  <div className="d-flex mt-2 align-items-center justify-content-between">
                    <button className="btn-card-lp">
                      <div className="lamp"></div>On Sale
                    </button>
                    <div className="d-flex align-items-center">
                      <MdFavorite
                        className="pointer"
                        size={24}
                        color="#FF45B4"
                      />
                      <button
                        className="btn btn-dark ms-2"
                        style={{
                          height: "36px",
                          borderRadius: "5px",
                          padding: "5px 16px",
                        }}
                      >
                        View Project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div className="cardInnerFR w-100 card-white p-4">
                <img
                  src={img1}
                  className="imgFR"
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "20px",
                    objectFit: "cover",
                  }}
                />
                <div className="rightFR w-100 d-flex flex-column justify-content-center ">
                  <div className="d-flex align-items-end justify-content-between mb-3">
                    <div className="flexCFR">
                      <p className="text-card-s2 mb-0">Andromeda Project</p>
                      <p className="mb-0 flexCFRtext">(Villa Sanur)</p>
                    </div>
                    <p className="text-card-s2 mb-0">90%</p>
                  </div>
                  <progress style={{ marginBottom: "8px" }} value={0.9} />{" "}
                  <div className="d-flex mt-2 align-items-center justify-content-between">
                    <button className="btn-card-lp">
                      <div className="lamp"></div>On Sale
                    </button>
                    <div className="d-flex align-items-center">
                      <MdFavorite
                        className="pointer"
                        size={24}
                        color="#FF45B4"
                      />
                      <button
                        className="btn btn-dark ms-2"
                        style={{
                          height: "36px",
                          borderRadius: "5px",
                          padding: "5px 16px",
                        }}
                      >
                        View Project
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FundRaisesProgress;
