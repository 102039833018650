import React, { useState, useEffect, useRef } from "react";
import load from "../assets/loading.json";
import Lottie from "lottie-web";

const Loading = ({ end }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const anim = Lottie.loadAnimation({
      container: containerRef.current,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: load, // Lottie animation data
    });

    return () => {
      anim.destroy();
    };
  }, []);

  return (
    <div>
      <div className={`loading-spinner ${!end ? "fade-out" : ""}`}>
        <div style={{ position: "relative" }}>
          <div ref={containerRef} style={{ width: "300px" }}></div>
          <p
            className="mb-0 text-center text-loading"
            style={{ position: "relative", top: "-80px" }}
          >
            Loading
          </p>
        </div>
      </div>{" "}
    </div>
  );
};

export default Loading;
