import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Home from "./pages/home/Home";
import Ecosystem from "./pages/ecosystem/Ecosystem";
import Dashboard from "./pages/defi/dashboard/Dashboard";
import Project from "./pages/defi/project/Project";
import ProjectDetail from "./pages/defi/project/ProjectDetail";
import Uconst from "./component/UnderConst";
import { Account } from "./pages/defi/account/Account";
import { EscrowApp } from "./pages/defi/p2p/Escrow";
import SellList from "./pages/defi/p2p/sell/SellList";
import MyOrder from "./pages/defi/p2p/order/MyOrder";
import axios from "axios";
import { News } from "./pages/defi/news/News";
import { Buyback } from "./pages/defi/buyback/Buyback";
import NewsDetail from "./pages/defi/news/NewsDetail";
import FundRaisesProgress from "./pages/defi/dashboard/FundRaisesProgress";

function App() {
  axios.defaults.baseURL = "http://localhost:5000";

  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ecosystem" element={<Ecosystem />} />
            <Route path="/under-construction" element={<Uconst />} />
            <Route path="/escrow" element={<EscrowApp />} />
            <Route path="/sell-list" element={<SellList />} />
            <Route path="/my-order" element={<MyOrder />} />
            <Route path="/news" element={<News />} />
            <Route path="/news-detail" element={<NewsDetail />} />
            <Route path="/account" element={<Account />} />
            <Route path="/buyback" element={<Buyback />} />
            {/* ---- */}
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/project" element={<Project />} />
            <Route path="/project-detail" element={<ProjectDetail />} />
            <Route path="/fund-raises" element={<FundRaisesProgress />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
