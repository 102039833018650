import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { SidebarDefi } from "../../../component/defi/SidebarDefi";
import { NavbarDefi } from "../../../component/defi/NavbarDefi";
import { Tab, Nav } from "react-bootstrap";
import logo from "../../../assets/examtoken.png";
import bgpol from "./polbg.png";
import FlipClockCountdown from "@leenguyen/react-flip-clock-countdown";
import "@leenguyen/react-flip-clock-countdown/dist/index.css";
import { IoIosArrowDown } from "react-icons/io";
import bb1 from "../../../assets/bb1.png";
import bb2 from "../../../assets/bb2.png";

import "./style.css";

export function Buyback() {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <SidebarDefi />

      <div className="body-dashboard">
        <NavbarDefi />
        <div className="balance-info">
          <Tab.Container defaultActiveKey="sell">
            <Nav
              variant="tabs"
              style={{ border: "none" }}
              className="justify-content-center"
            >
              <Nav.Item>
                <Nav.Link className="btnTab" eventKey="sell">
                  Buy Back
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="btnTab" eventKey="buy">
                  Burning
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="btnTab" eventKey="myorder">
                  Missing Event
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <hr className="mt-2 mb-3" />

            <Tab.Content>
              <Tab.Pane eventKey="sell">
                <div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                      <div className="cardBb">
                        <img src={bb1} className="bb1" />
                        <img src={bb2} className="bb2" />
                        <div className="imgTitle">
                          <img src={bgpol} className="bgpol" />
                          <img src={logo} className="imgbg" />
                        </div>
                        <br />
                        <br />
                        <div className="cardBbInner">
                          <p className="text-center titleBB mb-2">
                            2nd Buy Back Ticket
                          </p>
                          <p className="text-center titleBB outlineText">10%</p>
                          <button className="d-flex mx-auto btn-releasebb">
                            Release Countdown
                          </button>
                          <br />
                          <FlipClockCountdown
                            className="d-flex justify-content-center"
                            to={new Date().getTime() + 24 * 3600 * 1000} // set the countdown to 24 hours from now
                            labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                            labelStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                            digitBlockStyle={{
                              width: 25,
                              height: 30,
                              fontSize: 20,
                              background: "white",
                              color: "#7a44d9",
                            }}
                            separatorStyle={{ color: "white", size: "6px" }}
                            duration={0.5}
                          />
                          <br />
                          <p
                            className="text-center titleBB"
                            style={{ fontSize: "40px" }}
                          >
                            $251.56
                          </p>
                          <div className="d-flex justify-content-between cardsold">
                            <p>Sold</p>
                            <div>
                              <p>67/100</p>
                            </div>
                          </div>
                          <br />
                          <button
                            className="btn btn-light btn-sm d-flex mx-auto p-2 mb-3"
                            style={{ borderRadius: "100%" }}
                            onClick={handleToggle}
                          >
                            <IoIosArrowDown />
                          </button>
                          {isExpanded ? (
                            <div>
                              <p
                                className="text-light"
                                style={{ textAlign: "justify" }}
                              >
                                This buyback event is being held before the
                                appraisal results are out and will close a few
                                days before the appraisal results are released.
                                <br />
                                If you want your assets to be bought back at a
                                pre-determined price, you can click "Get Ticket"
                                and if you don't want to be bought back or don't
                                get a slot for the buyback event this time, you
                                have to wait for the next buyback event or
                                burning event.
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          <button className="btn btn-dark mx-auto d-flex ">
                            Get Ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="buy">
                <div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                      <div className="cardBb2">
                        <img src={bb1} className="bb1" />
                        <img src={bb2} className="bb2" />
                        <div className="imgTitle">
                          <img src={bgpol} className="bgpol" />
                          <img src={logo} className="imgbg" />
                        </div>
                        <br />
                        <br />
                        <div className="cardBbInner2">
                          <p className="text-center titleBB mb-2">
                            Burning Event
                          </p>
                          <p className="text-center titleBB outlineText">
                            BURN 100%
                          </p>
                          <button className="d-flex mx-auto btn-releasebb">
                            Release Countdown
                          </button>
                          <br />
                          <FlipClockCountdown
                            className="d-flex justify-content-center"
                            to={new Date().getTime() + 24 * 3600 * 1000} // set the countdown to 24 hours from now
                            labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                            labelStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                            digitBlockStyle={{
                              width: 25,
                              height: 30,
                              fontSize: 20,
                              background: "white",
                              color: "#7a44d9",
                            }}
                            separatorStyle={{ color: "white", size: "6px" }}
                            duration={0.5}
                          />
                          <br />
                          <p
                            className="text-center titleBB"
                            style={{ fontSize: "40px" }}
                          >
                            $251.56
                          </p>
                          <div className="d-flex justify-content-between cardsold">
                            <p>Sold</p>
                            <div>
                              <p>67/100</p>
                            </div>
                          </div>
                          <br />
                          <button
                            className="btn btn-light btn-sm d-flex mx-auto p-2 mb-3"
                            style={{ borderRadius: "100%" }}
                            onClick={handleToggle}
                          >
                            <IoIosArrowDown />
                          </button>
                          {isExpanded ? (
                            <div>
                              <p
                                className="text-light"
                                style={{ textAlign: "justify" }}
                              >
                                Burning event is the final stage of a project
                                listed here. All tokens in circulation will be
                                purchased by the company. Click here to read
                                <br /> expected to sign the burning event
                                contract. If you don't sign within the specified
                                time period, we will burn the assets you own by
                                force without getting any compensation.
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          <button className="btn btn-dark mx-auto d-flex ">
                            Get Ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="myorder">
                <div>
                  <br />
                  <br />
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                      <div className="cardBbI">
                        <img src={bb1} className="bb1" />
                        <img src={bb2} className="bb2" />
                        <div className="imgTitle">
                          <img src={bgpol} className="bgpol" />
                          <img src={logo} className="imgbg" />
                        </div>
                        <br />
                        <br />
                        <div className="cardBbInner">
                          <p className="text-center titleBB mb-2">
                            2nd Buy Back Ticket
                          </p>
                          <p className="text-center titleBB outlineText">10%</p>
                          <button className="d-flex mx-auto btn-releasebb">
                            Release Countdown
                          </button>
                          <br />
                          <FlipClockCountdown
                            className="d-flex justify-content-center"
                            to={new Date().getTime() + 24 * 3600 * 1000} // set the countdown to 24 hours from now
                            labels={["DAYS", "HOURS", "MINUTES", "SECONDS"]}
                            labelStyle={{
                              fontSize: 10,
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                            digitBlockStyle={{
                              width: 25,
                              height: 30,
                              fontSize: 20,
                              background: "white",
                              color: "#7a44d9",
                            }}
                            separatorStyle={{ color: "white", size: "6px" }}
                            duration={0.5}
                          />
                          <br />
                          <p
                            className="text-center titleBB"
                            style={{ fontSize: "40px" }}
                          >
                            $251.56
                          </p>
                          <div className="d-flex justify-content-between cardsold">
                            <p>Sold</p>
                            <div>
                              <p>67/100</p>
                            </div>
                          </div>
                          <br />
                          <button
                            className="btn btn-light btn-sm d-flex mx-auto p-2 mb-3"
                            style={{ borderRadius: "100%" }}
                            onClick={handleToggle}
                          >
                            <IoIosArrowDown />
                          </button>
                          {isExpanded ? (
                            <div>
                              <p
                                className="text-light"
                                style={{ textAlign: "justify" }}
                              >
                                This buyback event is being held before the
                                appraisal results are out and will close a few
                                days before the appraisal results are released.
                                <br />
                                If you want your assets to be bought back at a
                                pre-determined price, you can click "Get Ticket"
                                and if you don't want to be bought back or don't
                                get a slot for the buyback event this time, you
                                have to wait for the next buyback event or
                                burning event.{" "}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                          <button className="btn btn-dark mx-auto d-flex ">
                            Get Ticket
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}
