import React, { useState, useEffect, useRef } from "react";
import "./home.css";
import tickPol from "../../assets/tickPolygon.png";
import img1s2 from "../../assets/s1-1.png";
import img2s2 from "../../assets/s1-2.png";
import img3s2 from "../../assets/s1-3.png";
import img1s3 from "../../assets/imgs3.png";
import img2s3 from "../../assets/imgs32.png";

import imgc1s3 from "../../assets/is31.png";
import imgc2s3 from "../../assets/is32.png";
import imgc3s3 from "../../assets/is33.png";

import imgs4rm from "../../assets/btn-roadmap.png";
import imgs5 from "../../assets/img-s5.png";

import g37 from "../../assets/g37.png";
import polygon from "../../assets/polygon.png";
import certik from "../../assets/certik.png";
import any from "../../assets/logoany.png";
import Footer from "../../component/Footer";
import Navbar from "../../component/Navbar";
import Counter from "../../component/Counter";
import Loading from "../../component/Loading";
import Particle from "../../component/Particle";
import { useNavigate } from "react-router-dom";

function Home() {
  const navigate = useNavigate();
  const data = [
    {
      id: 1,
      location: "Rawamangun, Jakarta Timur",
      valuation: "Rp 20.000.000.000",
      developmentTime: "365 Days",
      securityTokenSold: 0.3,
      type: "Orion",
      imgSrc: img1s2,
    },
    {
      id: 2,
      location: "Penatih Dangin Puri, Denpasar Timur, Bali",
      valuation: "Rp 50.000.000.000",
      developmentTime: "735 Days",
      securityTokenSold: 0.5,
      type: "Andromeda",
      imgSrc: img2s2,
    },
    {
      id: 3,
      location: "Menteng Dalem, Jakarta Selatan",
      valuation: "Rp 10.000.000.000",
      developmentTime: "365 Days",
      securityTokenSold: 0.7,
      type: "Aquila",
      imgSrc: img3s2,
    },
  ];

  const Card = ({ card }) => (
    <div className="col-12 col-sm-12 col-md-6 col-lg-4 mb-4 ">
      <div className="card-s2">
        <img
          className="w-100"
          src={card.imgSrc}
          alt={card.location}
          style={{ height: "250px", objectFit: "cover" }}
        />
        <div className="btn-card-s2">{card.type}</div>
        <div className="p-3">
          <p>{card.location}</p>
          <table className="w-100">
            <tbody>
              <tr>
                <td style={{ width: "45%" }}>
                  <p>Valuation</p>
                </td>
                <td style={{ width: "5%" }}>
                  <p>:</p>
                </td>
                <td style={{ width: "50%" }}>
                  <p>{card.valuation}</p>
                </td>
              </tr>
              <tr>
                <td style={{ width: "45%" }}>
                  <p>Development Time</p>
                </td>
                <td style={{ width: "5%" }}>
                  <p>:</p>
                </td>
                <td style={{ width: "50%" }}>
                  <p>{card.developmentTime}</p>
                </td>
              </tr>
              <tr>
                <td style={{ width: "45%" }}>
                  <p>Security Token Sold</p>
                </td>
                <td style={{ width: "5%" }}>
                  <p>:</p>
                </td>
                <td style={{ width: "50%" }}>
                  <progress
                    style={{ marginBottom: "8px" }}
                    value={card.securityTokenSold}
                  />{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="px-3 pb-3">
          <button onClick={() => navigate(`/dashboard`)} className="btn-buy">
            Buy
          </button>
        </div>
      </div>
    </div>
  );

  const [fade, setFade] = useState(true);
  const assets = [
    tickPol,
    img1s2,
    img3s2,
    img1s3,
    img2s3,
    imgc1s3,
    imgc2s3,
    imgc3s3,
    imgs4rm,
    imgs5,
    g37,
    polygon,
    certik,
    any,
  ];
  useEffect(() => {
    const loadAssets = async () => {
      const images = assets.map((item) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = item;
          img.onload = () => resolve(item);
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(images);
        setTimeout(() => {
          setFade(false); // Start the fade-out transition
        }, 1000);
      } catch (error) {
        console.error("Failed to load images", error);
      }
    };

    loadAssets();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const handleScroll1 = () => {
      const section = document.getElementById("s1");
      const animatedElements = section.querySelectorAll(".animated-bot-top");

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll1();

    const handleScroll2 = () => {
      const section = document.getElementById("s2");
      const animatedElements = section.querySelectorAll(
        ".animated-right-left, .animated-left-right",
      );

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll2();

    const handleScroll3 = () => {
      const section = document.getElementById("s3");
      const animatedElements = section.querySelectorAll(
        ".animated-right-left, .animated-left-right",
      );

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll3();
    const handleScroll4 = () => {
      const section = document.getElementById("s4");
      const animatedElements = section.querySelectorAll(
        ".animated-right-left, .animated-left-right, .animated-bot-top",
      );

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll4();

    const handleScroll5 = () => {
      const section = document.getElementById("s5");
      const animatedElements = section.querySelectorAll(
        ".animated-right-left, .animated-left-right, .animated-bot-top",
      );

      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      }, options);

      animatedElements.forEach((element) => {
        observer.observe(element);
      });
    };

    handleScroll5();
  }, []);

  return (
    <div style={{ position: "relative", overflow: "hidden" }}>
      <Navbar activePage={"Home"} />
      <Loading end={fade} />

      {/* ----------- */}
      <div
        className="section-1 "
        id="s1"
        style={{ position: "relative", zIndex: "2" }}
      >
        <div className="content-s1 ">
          <div
            className="d-flex align-items-center justify-content-center w-100 flex-column"
            style={{ position: "relative", minHeight: "80vh" }}
          >
            <p className="text1-s1 animated-bot-top">
              Future Real Estate Investment{" "}
            </p>
            <p className="text2-s1 animated-bot-top">
              Revolutionizing Projects with Innovative Funding{" "}
            </p>
            <button
              onClick={() => navigate(`/dashboard`)}
              className=" btn-s1 my-4 animated-bot-top"
            >
              Learn More
            </button>
            <p className="text3-s1 mt-2 animated-bot-top">
              BLOCKCHAIN SECURITY TOKEN BASE{" "}
            </p>
            <div className="card-s1-outer">
              <div className="card-s1-inner p-2">
                <img className="tickpol" src={tickPol} alt="" />
                <svg
                  className="bg-card-s1"
                  width="1148"
                  height="318"
                  viewBox="0 0 1148 318"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_117_71)">
                    <path
                      d="M64 24H424.945C442.148 24 458.446 31.4736 471.145 43.0774C494.325 64.2581 535.108 95.5 574 95.5C610.089 95.5 647.216 65.8054 669.055 44.6893C681.993 32.1802 698.937 24 716.933 24H1084C1106.09 24 1124 41.9086 1124 64V214C1124 258.183 1088.18 294 1044 294H104C59.8172 294 24 258.183 24 214V64C24 41.9086 41.9086 24 64 24Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_117_71"
                      x="0"
                      y="0"
                      width="1148"
                      height="318"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="12" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_117_71"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_117_71"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
                <div className="c-card">
                  <div className="row g-0">
                    <div className="col">
                      <Counter target="50" prefix="$ " suffix=" M+" />{" "}
                      <p className="texts1-card1">Fund Raised</p>
                    </div>
                    <div className="col">
                      <Counter target="3" suffix=" +" />{" "}
                      <p className="texts1-card1">Project Listed</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------- */}
      <div className="section-2 " id="s2" style={{ position: "relative" }}>
        <div className="s2-inner">
          <div className="container">
            <div className="d-flex align-items-center">
              <hr
                className="rounded-hr"
                style={{
                  width: "40px",
                  border: "2px solid black",
                  opacity: "90%",
                }}
              />
              <p
                className="mb-0 ms-4 animated-right-left"
                style={{ fontSize: "24px", fontWeight: "700" }}
              >
                ECOSYSTEM
              </p>
            </div>
            <p className="mb-0 mt-2 title-s2 animated-right-left">
              LIST OF ECOSYSTEM PROJECT
            </p>
            <br />
            <div className="row animated-right-left">
              {data.map((card) => (
                <Card key={card.id} card={card} />
              ))}
            </div>{" "}
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------- */}
      <div
        className="section-3 "
        id="s3"
        style={{ position: "relative", zIndex: "0" }}
      >
        <br />
        <br />
        <div
          style={{
            backgroundColor: "#00050e",
            zIndex: "1",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div className="container">
            <br />
            <div className="d-flex align-items-center justify-content-end">
              <p
                className="mb-0 me-4 animated-left-right"
                style={{ fontSize: "24px", fontWeight: "700" }}
              >
                DECENTRALIZED FINANCE
              </p>
              <hr
                className="rounded-hr"
                style={{
                  width: "40px",
                  border: "2px solid white",
                  opacity: "90%",
                }}
              />
            </div>
            <p className="mb-0 mt-2 title-s2 text-end mb-5 animated-left-right">
              De-Fi Features{" "}
            </p>
            <div className="row">
              <div
                style={{ position: "relative" }}
                className="col-12 mb-4 col-sm-12 col-md-4 col-lg-4 d-flex align-items-center justify-content-center"
              >
                <img
                  className="img-s31 animated-left-right"
                  src={img1s3}
                  style={{ zIndex: "2" }}
                />
                <div className="cbg"></div>
                <div className="cbg2"></div>
              </div>
              <div className="col-12 mb-4 col-sm-12 col-md-8 col-lg-8 d-flex align-items-center justify-content-center flex-column">
                <img
                  className="img-s32 animated-right-left"
                  src={img2s3}
                  alt=""
                />
                <button
                  onClick={() => navigate(`/dashboard`)}
                  className="btn-defi mt-5"
                >
                  DE-FI PROTOCOL
                </button>
              </div>
            </div>
            <br />
            <br />
            {/* ----------- */}

            <div className="w-100">
              <div
                className="row"
                style={{ position: "relative", zIndex: "1" }}
              >
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div className="card-s3d-1">
                    <div className="card-inner-s3d">
                      <div className="card-front-s3d">
                        <div className="cardin-s3d animated-right-left">
                          <img src={imgc1s3} className="imgcs3" />
                          <p className="mb-0 text-card-s3">
                            Audited Project Location
                          </p>
                        </div>
                      </div>
                      <div className="card-back-s3d">
                        <div className="cardin-s3d">
                          <p className="text-back-s3d">
                            All projects on Launchpad have had their project
                            locations audited.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div className="card-s3d-1">
                    <div className="card-inner-s3d">
                      <div className="card-front-s3d">
                        <div className="cardin-s3d animated-right-left">
                          <img src={imgc2s3} className="imgcs3" />
                          <p className="mb-0 text-card-s3">Audited Permit </p>
                        </div>
                      </div>
                      <div className="card-back-s3d">
                        <div className="cardin-s3d">
                          <p className="text-back-s3d">
                            Several documents such as building permits have been
                            audited by the company and other parties.{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 mb-4">
                  <div className="card-s3d-1">
                    <div className="card-inner-s3d animated-right-left">
                      <div className="card-front-s3d">
                        <div className="cardin-s3d">
                          <img src={imgc3s3} className="imgcs3" />
                          <p className="mb-0 text-card-s3">
                            Fund Allocation Report{" "}
                          </p>
                        </div>
                      </div>
                      <div className="card-back-s3d">
                        <div className="cardin-s3d">
                          <p className="text-back-s3d">
                            The collected fund raising results will be given a
                            report based on the project development allocation
                            in stages{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <br />
            <br />
          </div>
        </div>
      </div>
      {/* ---------------------------------------------------------- */}
      <div
        className="section-4"
        id="s4"
        style={{ position: "relative", zIndex: "0" }}
      >
        <div className="content-s4 ">
          <img className="imgts4" src={tickPol} alt="" />
          <img className="imgts4r" src={tickPol} alt="" />

          <div
            className="container d-flex flex-column align-items-center justify-content-around"
            style={{ height: "100%", minHeight: "90vh" }}
          >
            <p className="text-s4 animated-bot-top">
              Future Real Estate Ecosystem
            </p>
            <button
              onClick={() => navigate(`/dashboard`)}
              className="btn-s1 animated-bot-top"
              style={{ marginBottom: "100px" }}
            >
              Explore Ecosystem
            </button>
          </div>
        </div>
        <div className="d-flex container justify-content-around">
          <p className="text-s4-rm" style={{ width: "100%" }}>
            ROAD
          </p>
          <img src={imgs4rm} className="btn-rm" />
          <p className="text-s4-rm" style={{ width: "100%" }}>
            MAP{" "}
          </p>
        </div>
      </div>
      {/* ---------------------------------------------------------- */}
      <div
        className="section-5"
        id="s5"
        style={{ position: "relative", zIndex: "0" }}
      >
        <div className="container">
          <p className="text-s5 animated-left-right">PLATFORM</p>
          <br />
          <div className="card-s5 p-4 animated-left-right">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 my-2 d-flex align-items-center justify-content-center">
                <p className="text-card-s5 mb-0">QUARTAL 3</p>
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2 d-flex align-items-center justify-content-start">
                <p className="mb-0">
                  - Website Development <br />- Infrastructure Development{" "}
                  <br />- Create Security Token <br />- PolygonScan Registration
                  Token <br />- Security Token Offering
                </p>
              </div>
            </div>
          </div>
          <br />
          <br />
          <div className="card-s5 p-4 animated-left-right">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-6 my-2 d-flex align-items-center justify-content-center">
                <p className="text-card-s5 mb-0">QUARTAL 4</p>
              </div>
              <div className="col-12 col-sm-12 col-md-6 my-2 d-flex align-items-center justify-content-start">
                <p className="mb-0">
                  - Social Media Development <br />- Blockchain Partnership
                  Announcement <br />- Contractor Partnership Announcement
                </p>
              </div>
            </div>
          </div>
          <br />
          <div className="d-flex justify-content-center">
            <img src={imgs5} className="img-s5 animated-right-left" />
          </div>
          <br />
          <br />
          <hr />
          <br />
          <div className="d-flex align-items-center justify-content-center">
            <hr
              className="rounded-hr"
              style={{
                width: "40px",
                border: "2px solid white",
                opacity: "90%",
              }}
            />
            <p
              className="mb-0 mx-4 animated-bot-top"
              style={{ fontSize: "24px", fontWeight: "700", color: "white" }}
            >
              PARTNERSHIP
            </p>
            <hr
              className="rounded-hr"
              style={{
                width: "40px",
                border: "2px solid white",
                opacity: "90%",
              }}
            />
          </div>
          <br />
          <div className="row">
            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
              <div className="card-partner animated-bot-top">
                <img src={g37} className="w-100" />
                <h3 className="text-center text-light">G37 DEV</h3>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
              <div className="card-partner animated-bot-top">
                <img src={certik} className="w-100" />
                <h3 className="text-center text-light">CERTIK</h3>
              </div>
            </div>
            <div className="col-6 col-sm-6 col-md-6 col-lg-4">
              <div className="card-partner animated-bot-top">
                <img src={polygon} className="w-100" />
                <h3 className="text-center text-light">POLYGON</h3>
              </div>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
      {/* ---------------------------------------------------------- */}
      <Footer />
      <Particle />
    </div>
  );
}

export default Home;
