import React, { useState, useEffect } from "react";
import Web3 from "web3";
import "../style.css";
import "./modalStyle.css";
import { Dropdown } from "react-bootstrap";
import axios from "axios";
import { CiCircleInfo } from "react-icons/ci";

const SellModald = ({ openCO }) => {
  const [co, setCo] = useState(openCO);
  const [selectedOption, setSelectedOption] = useState("");
  const [tokenList, setTokenList] = useState([
    {
      id: 1,
      nameToken: "Andromeda",
      tokenBalance: 100000,
      symbolToken: "TA",
    },
  ]);

  const [TSTAmount, setTSTAmount] = useState("");
  const [USDTAmount, setUSDTAmount] = useState("");

  useEffect(() => {
    setCo(openCO);
  }, [openCO]);

  const handleSelect = (projectid, token) => {
    setSelectedOption({
      token: token,
      projectId: projectid,
    }); // Set pilihan yang dipilih
  };

  const handleMax = (max) => {
    if (max) {
      setTSTAmount(max);
    } else {
      setTSTAmount(0);
    }
  };

  const [errorTSTamount, setErrorTSTamount] = useState("");
  return (
    <div className="">
      <Dropdown className="my-3">
        <Dropdown.Toggle className="tokenToggle" id="dropdown-basic">
          {selectedOption.token || "Project Token"}{" "}
        </Dropdown.Toggle>

        <Dropdown.Menu className="menudropdown">
          {tokenList.length === 0 ? (
            <Dropdown.Item disabled>No tokens available</Dropdown.Item>
          ) : (
            tokenList.map((token, index) => (
              <Dropdown.Item
                key={index}
                onClick={() =>
                  handleSelect(
                    token.id,
                    token.nameToken,
                    token.balance,
                    token.symbolToken,
                  )
                }
              >
                {token.nameToken}
              </Dropdown.Item>
            ))
          )}
        </Dropdown.Menu>
      </Dropdown>

      <div className="contTo mb-2">
        <p className="mb-0 text-end" style={{ fontSize: "11px", color: "red" }}>
          {errorTSTamount}
        </p>
        <div className="d-flex justify-content-between mb-1">
          <p className="mb-0">Amount</p>
          <div className="d-flex">
            <p className="mb-0 textCo">Available : </p>
            <p className="mb-0 textCo ms-1">100000 TA</p>
          </div>
        </div>
        <div className="inputCO d-flex align-items-center">
          <input
            disabled={!selectedOption}
            placeholder="e.g 100"
            type="number"
            className="inputCOinner"
            value={TSTAmount}
            onChange={(e) => setTSTAmount(e.target.value)}
          />
          <p
            onClick={() => {
              if (selectedOption) {
                handleMax(100000);
                setErrorTSTamount("");
              }
            }}
            className="mb-0 textCo pointer"
          >
            Max
          </p>
          <p className="mb-0 ms-1 fw-semibold">TA</p>
        </div>
      </div>
      <div className="contTo">
        <div className="d-flex justify-content-between">
          <p className="mb-1">Initial Price</p>
        </div>
        <div className="inputCO d-flex align-items-center">
          <input
            disabled={!selectedOption}
            placeholder="e.g 200"
            type="number"
            className="inputCOinner"
            value={USDTAmount}
            onChange={(e) => setUSDTAmount(e.target.value)}
          />
          <p className="mb-0 fw-semibold">USDT</p>
        </div>
      </div>
      <br />
      <div>
        <div className="d-flex justify-content-between">
          <p className="mb-2" style={{ fontSize: "12px", opacity: "60%" }}>
            USDT Receive
            <CiCircleInfo
              className="ms-1 pointer"
              style={{ opacity: 1 }}
              color="grey"
              size={12}
            />{" "}
          </p>
          <div>
            <p className="mb-2 textCo" style={{ fontSize: "12px" }}>
              = {USDTAmount * TSTAmount} USDT
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <p className="mb-2" style={{ fontSize: "12px", opacity: "60%" }}>
            Processing Fee
            <CiCircleInfo
              className="ms-1 pointer"
              style={{ opacity: 1 }}
              color="grey"
              size={12}
            />{" "}
          </p>
          <div>
            <p className="mb-2 textCo" style={{ fontSize: "12px" }}>
              0.1%
            </p>
          </div>
        </div>{" "}
        <div className="d-flex justify-content-between">
          <p className="mb-2" style={{ fontSize: "12px", opacity: "60%" }}>
            Est.Gass Fee
            <CiCircleInfo
              className="ms-1 pointer"
              style={{ opacity: 1 }}
              color="grey"
              size={12}
            />{" "}
          </p>
          <div>
            <p className="mb-2 textCo" style={{ fontSize: "12px" }}>
              0.1 MATIC
            </p>
          </div>
        </div>
        <hr className="mb-2 mt-0" />
        <div className="d-flex mb-1">
          <p className="me-2 mb-0" style={{ fontSize: "11px", opacity: "60%" }}>
            -
          </p>
          <p style={{ fontSize: "11px", opacity: "60%" }} className="mb-0">
            Make sure your MATIC balance are available before confirm the
            transaction.
          </p>
        </div>
        <div className="d-flex mb-1">
          <p className="me-2 mb-0" style={{ fontSize: "11px", opacity: "60%" }}>
            -
          </p>
          <p style={{ fontSize: "11px", opacity: "60%" }} className="mb-0">
            After confirm, your TA will be locked in our smart contract until
            the order filled or canceled.
          </p>
        </div>
        <div className="d-flex mb-1">
          <p className="me-2 mb-0" style={{ fontSize: "11px", opacity: "60%" }}>
            -
          </p>
          <p style={{ fontSize: "11px", opacity: "60%" }} className="mb-0">
            Once order filled, your TA will automatically release to buyer and
            you will get USDT at the same time.
          </p>
        </div>
        <button
          className="btn-buy mt-3"
          // onClick={createEscrowTx}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default SellModald;
