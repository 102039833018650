import React, { useState, useEffect, useRef } from "react";
import img from "../assets/under-contruction.png";
import Loading from "./Loading";
import { useNavigate } from "react-router-dom";
const Uconst = () => {
  const navigate = useNavigate();
  const [fade, setFade] = useState(true);
  const assets = [img];
  useEffect(() => {
    const loadAssets = async () => {
      const images = assets.map((item) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = item;
          img.onload = () => resolve(item);
          img.onerror = reject;
        });
      });

      try {
        await Promise.all(images);
        setTimeout(() => {
          setFade(false); // Start the fade-out transition
        }, 1000);
      } catch (error) {
        console.error("Failed to load images", error);
      }
    };

    loadAssets();
  }, []);

  return (
    <div>
      <Loading end={fade} />
      <div className="uc-bg d-flex flex-column align-items-center justify-content-center">
        <p className="text1-s1 text-white">
          Sorry, We’Are Under Construction. Come Back Later !!
        </p>
        <br />
        <button onClick={() => navigate(-1)} className=" btn-s1 my-4 ">
          Back
        </button>
      </div>
    </div>
  );
};

export default Uconst;
