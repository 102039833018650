import { createStore } from "redux";
import reducer from "./reducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // menggunakan localStorage sebagai penyimpanan

// Konfigurasi persist
const persistConfig = {
  key: "root",
  storage,
};

// Buat reducer yang terpersist
const persistedReducer = persistReducer(persistConfig, reducer);

// Buat store menggunakan reducer yang telah dipersist
const store = createStore(
  persistedReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);

const persistor = persistStore(store);

export { store, persistor };
