import React, { useState, useEffect } from "react";
import "../style.css";
import "../order/modalStyle.css";
import ReactSlider from "react-slider";
import { Tab, Nav } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";

const BuyModal = ({ openBM, closeBMbutton }) => {
  const [co, setCo] = useState(openBM);

  useEffect(() => {
    setCo(openBM);
  }, [openBM]);

  const handleClose = () => {
    closeBMbutton(); // Panggil fungsi menutup dari props
  };

  const [errorUSDT, setErrorUSDT] = useState("");
  const [TSTAmount, setTSTAmount] = useState("");

  const handleMax = (max) => {
    if (max) {
      setTSTAmount(max);
    } else {
      setTSTAmount(0);
    }
  };

  useEffect(() => {
    if (TSTAmount > 500) {
      setErrorUSDT("Insufficient Balance");
    } else {
      setErrorUSDT("");
    }
    console.log(TSTAmount);
  }, [TSTAmount]);

  return (
    <div className="">
      {co && (
        <div className="blocker">
          <div className="ordermodal">
            <div className="ordermodalInner">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="mb-0 fw-bold">Buy TA</h3>
                <IoClose onClick={handleClose} className="pointer" size={24} />
              </div>
              <div className="d-flex">
                <p
                  className="mb-0"
                  style={{ opacity: "60%", lineHeight: "30px" }}
                >
                  Price
                </p>
                <p className="mb-0 ms-2 usdtPrice" style={{ fontSize: "16px" }}>
                  200 USDT/TA
                </p>
              </div>
              <hr className="mt-2" />
              <div className="contTo mb-2">
                <p
                  className="mb-0 text-end"
                  style={{ fontSize: "11px", color: "red" }}
                >
                  {errorUSDT}
                </p>
                <div className="d-flex justify-content-between mb-1">
                  <p className="mb-0">Amount</p>
                  <p className="mb-0 usdtPrice" style={{ lineHeight: "24px" }}>
                    Available : 500 USDT
                  </p>
                </div>
                <div className="inputCO d-flex align-items-center">
                  <input
                    placeholder="e.g 100"
                    type="number"
                    className="inputCOinner"
                    value={TSTAmount}
                    onChange={(e) => setTSTAmount(e.target.value)}
                  />
                  <p
                    onClick={() => {
                      handleMax(500);
                      setErrorUSDT("");
                    }}
                    className="mb-0 textCo pointer"
                  >
                    Max
                  </p>
                  <p className="mb-0 ms-1 fw-semibold">TA</p>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <p
                    className="mb-2"
                    style={{ fontSize: "12px", opacity: "60%" }}
                  >
                    TA Receive
                  </p>
                  <div>
                    <p className="mb-2 textCo" style={{ fontSize: "12px" }}>
                      = {TSTAmount} TA
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <p
                    className="mb-2"
                    style={{ fontSize: "12px", opacity: "60%" }}
                  >
                    Processing Fee
                  </p>
                  <div>
                    <p className="mb-2 textCo" style={{ fontSize: "12px" }}>
                      0.1%
                    </p>
                  </div>
                </div>{" "}
                <div className="d-flex justify-content-between">
                  <p
                    className="mb-2"
                    style={{ fontSize: "12px", opacity: "60%" }}
                  >
                    Est.Gass Fee
                  </p>
                  <div>
                    <p className="mb-2 textCo" style={{ fontSize: "12px" }}>
                      0.1 MATIC
                    </p>
                  </div>
                </div>
                <hr className="mb-3 mt-2" />
                <div className="d-flex mb-1">
                  <p
                    className="me-2 mb-0"
                    style={{ fontSize: "11px", opacity: "60%" }}
                  >
                    -
                  </p>
                  <p
                    style={{ fontSize: "11px", opacity: "60%" }}
                    className="mb-0"
                  >
                    Make sure your MATIC balance are available before confirm
                    the transaction.
                  </p>
                </div>
                <div className="d-flex mb-1">
                  <p
                    className="me-2 mb-0"
                    style={{ fontSize: "11px", opacity: "60%" }}
                  >
                    -
                  </p>
                  <p
                    style={{ fontSize: "11px", opacity: "60%" }}
                    className="mb-0"
                  >
                    After confirm, your TA will be locked in our smart contract
                    until the order filled or canceled.
                  </p>
                </div>
                <div className="d-flex mb-1">
                  <p
                    className="me-2 mb-0"
                    style={{ fontSize: "11px", opacity: "60%" }}
                  >
                    -
                  </p>
                  <p
                    style={{ fontSize: "11px", opacity: "60%" }}
                    className="mb-0"
                  >
                    Once order filled, your TA will automatically release to
                    buyer and you will get USDT at the same time.
                  </p>
                </div>
                <br />
                <div>
                  <br />
                  <div className="d-flex">
                    <button className="btn btn-buy text-light w-100 ms-2">
                      Buy
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BuyModal;
