import React from "react";
import { SidebarDefi } from "../../../component/defi/SidebarDefi";
import { NavbarDefi } from "../../../component/defi/NavbarDefi";
import { Tab, Nav } from "react-bootstrap";
import "./style.css";
import SellList from "./sell/SellList";
import MyOrder from "./order/MyOrder";
import Buylist from "./buy/BuyList";

export function EscrowApp() {
  return (
    <div>
      <SidebarDefi />
      <div className="body-dashboard">
        <NavbarDefi />
        <div className="conEscrow">
          <Tab.Container defaultActiveKey="sell">
            <Nav
              variant="tabs"
              style={{ border: "none" }}
              className="justify-content-center"
            >
              <Nav.Item>
                <Nav.Link className="btnTab" eventKey="sell">
                  Buy
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="btnTab" eventKey="buy">
                  Sell
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className="btnTab" eventKey="myorder">
                  My Order
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <hr className="mt-2 mb-3" />

            <Tab.Content>
              <Tab.Pane eventKey="sell">
                <Buylist />
              </Tab.Pane>
              <Tab.Pane eventKey="buy">
                <SellList />
              </Tab.Pane>
              <Tab.Pane eventKey="myorder">
                <MyOrder />
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
}
