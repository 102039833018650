import React, { useState, useEffect } from "react";
import { SidebarDefi } from "../../../component/defi/SidebarDefi";
import { NavbarDefi } from "../../../component/defi/NavbarDefi";
import img1 from "./assets/img1.png";
import tier1 from "./assets/tier1.png";
import "./project.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { Dropdown } from "react-bootstrap";
import { MdFavorite } from "react-icons/md";
const Project = () => {
  const navigate = useNavigate();

  const [projects, setProjects] = useState([
    {
      name: "Andromeda",
      symbol: "TA",
      soft: 600,
      sold: 300,
      lockTime: 360,
      end: "99:25:59:59",
    },
    {
      name: "Andromeda",
      symbol: "TA",
      soft: 200,
      sold: 30,
      lockTime: 360,
      end: "99:25:59:59",
    },
    {
      name: "Andromeda",
      symbol: "TA",
      soft: 100,
      sold: 0,
      lockTime: 360,
      end: "99:25:59:59",
    },
  ]);

  // const fetchEscrowTxs = async () => {
  //   try {
  //     const response = await axios.get("/api/projects"); // Ganti URL sesuai backend API
  //     setProjects(response.data);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // useEffect(() => {
  //   fetchEscrowTxs(); // Panggil fungsi untuk mengambil data saat komponen dimuat
  // }, []);
  // console.log(projects);

  return (
    <div>
      <SidebarDefi />
      <div className="body-dashboard">
        <NavbarDefi />
        <div className="content-dashboard">
          <div className="row">
            <div className="col -12 col-sm-12 col-md-4 col-lg-4  col-xl-5 d-flex align-items-center">
              <div className="d-flex justify-content-between w-100">
                <div className="inputCO2" style={{ maxWidth: "unset" }}>
                  <input
                    type="text"
                    className="inputCOinner"
                    placeholder="Type project name, project address to find your needs"
                  />
                </div>
              </div>
            </div>
            <div className="col -12 col-sm-12 col-md-8 col-lg-8 col-xl-7">
              <div className="d-flex conBtnHP ">
                <Dropdown className="my-3 me-2 w-100">
                  <Dropdown.Toggle
                    className="btn-filter resL fw-semibold px-3 py-2 w-100"
                    style={{ backgroundColor: "#dadada" }}
                  >
                    {"AllStatus"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menudropdown">
                    <Dropdown.Item>Up Coming</Dropdown.Item>
                    <Dropdown.Item>On Sale</Dropdown.Item>
                    <Dropdown.Item>Ended</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown className="my-3 w-100">
                  <Dropdown.Toggle
                    className="btn-filter resL fw-semibold px-3 py-2 w-100"
                    style={{ backgroundColor: "#dadada" }}
                  >
                    {"Lock Time"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menudropdown">
                    <Dropdown.Item disabled>No items</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>

                <Dropdown className="my-3 ms-2 me-2 w-100">
                  <Dropdown.Toggle
                    className="btn-filter resL fw-semibold px-3 py-2 w-100"
                    style={{ backgroundColor: "#dadada" }}
                  >
                    {"Soft Cap"}
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menudropdown">
                    <Dropdown.Item disabled>No items</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button className="btn-filter resfav fw-semibold px-3 py-2 m-auto">
                  <MdFavorite className="symresL" color="#FF45B4" />
                </button>
              </div>
            </div>
          </div>

          <br />

          <div className="row">
            {projects.map((project) => (
              <div
                className="col-12 col-md-6 col-lg-6 col-xl-4 mb-4"
                key={project.id}
              >
                <div
                  className="card shadow card-lp border-0 mx-auto"
                  style={{ position: "relative", overflow: "hidden" }}
                >
                  <img
                    src={img1}
                    className="w-100"
                    style={{ minHeight: "200px", objectFit: "cover" }}
                    alt={project.name}
                  />
                  <div className="p-3">
                    <div className="d-flex align-items-center justify-content-between">
                      <img
                        src={tier1}
                        style={{ borderRadius: "100%", width: "50px" }}
                        alt="Tier"
                      />
                      <button className="btn-card-lp">
                        {" "}
                        <div className="lamp"></div>On Sale
                      </button>
                    </div>
                    <br />
                    <p className="text-card-lp">{project.name}</p>
                    <table className="w-100">
                      <tr>
                        <td style={{ width: "40%" }}>
                          <p>Soft</p>
                        </td>
                        <td style={{ width: "10%" }}>
                          <p>:</p>
                        </td>
                        <td className="text-tb" style={{ width: "60%" }}>
                          <p>
                            {project.soft} {project.symbol}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ width: "40%" }}>
                          <p>Lock Time</p>
                        </td>
                        <td style={{ width: "10%" }}>
                          <p>:</p>
                        </td>
                        <td className="text-tb" style={{ width: "60%" }}>
                          <p>{project.lockTime} Days</p>
                        </td>
                      </tr>
                    </table>
                    <p
                      className="text-center mb-0"
                      style={{ fontSize: "14px" }}
                    >
                      Progress ({(project.sold / project.soft) * 100}%)
                    </p>
                    <progress
                      value={(project.sold / project.soft) * 100}
                      max="100"
                    />
                    <div className="d-flex align-items-center justify-content-between">
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        {project.sold} {project.symbol}
                      </p>
                      <p className="mb-0" style={{ fontSize: "12px" }}>
                        {project.soft} {project.symbol}
                      </p>
                    </div>
                    <br />
                    <div className="d-flex align-items-center justify-content-between">
                      <div>
                        <p className="mb-0">Ends in :</p>
                        <p className="mb-0 fw-bold">{project.end} </p>
                      </div>
                      <div className="d-flex">
                        <button
                          className="btn-connect"
                          style={{
                            height: "36px",
                            borderRadius: "5px",
                            padding: "5px 16px",
                            width: "76px",
                          }}
                        >
                          Buy
                        </button>
                        <button
                          onClick={() => navigate(`/project-detail`)}
                          className="btn btn-dark ms-2"
                          style={{
                            height: "36px",
                            borderRadius: "5px",
                            padding: "5px 16px",
                            width: "76px",
                          }}
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
