import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import { IoShareSocialOutline } from "react-icons/io5";
import { CiCircleInfo } from "react-icons/ci";
import { FcLike } from "react-icons/fc";
import { SlGlobe } from "react-icons/sl";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { FaDiamond } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";
import { FaLock } from "react-icons/fa";

import tier1 from "./assets/tier1.png";
import "./project.css";

import img1 from "./assets/1.png";
import img2 from "./assets/2.png";
import img3 from "./assets/3.png";
import img4 from "./assets/4.png";
import img5 from "./assets/5.png";

import ic1 from "./assets/ic1.png";
import ic2 from "./assets/ic2.png";
import ic3 from "./assets/ic3.png";
import ic4 from "./assets/ic4.png";

import imgh from "./assets/img1.png";

import map from "./assets/map.png";
import { useNavigate } from "react-router-dom";

const ProjectDetail = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="">
        <div className="d-flex w-100" style={{ padding: "20px" }}>
          <div
            className="pointer d-flex align-items-center"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack size={24} />
            <p className="mb-0 ms-2 textHead">Project Fundamental </p>
          </div>
        </div>
        <div className="content-dashboard">
          <div className="row">
            <div className="mb-3 col-sm-12 col-md-12 col-lg-4 col-xl-3">
              <div className="cardP">
                <img
                  src={tier1}
                  className="mx-auto d-flex"
                  style={{ borderRadius: "100%" }}
                />
                <div className="">
                  <p className="mb-0 text-center text-titlep mt-2">
                    Andromeda Project
                  </p>
                  <p className="mb-2 text-center text-p">Villa Sanur</p>
                  <p
                    className="mb-0 text-center"
                    style={{
                      fontSize: "24px",
                      color: "#004BFF",
                      fontWeight: "700",
                      fontSize: "14px",
                    }}
                  >
                    $203.75
                  </p>
                </div>
                <div className="d-flex mt-3 justify-content-center">
                  <div className="btnCP me-1">
                    <IoShareSocialOutline className="mx-2" size={22} />
                  </div>
                  <div className="btnCP ms-1">
                    <FcLike className="mx-2" size={22} />
                    <p
                      className="mb-0 me-2 mt-1"
                      style={{
                        color: "#004BFF",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      3.5K
                    </p>
                  </div>
                </div>
                <br />
                <div>
                  <div className="d-flex justify-content-between">
                    <p style={{ fontSize: "14px" }}>
                      Ticker{" "}
                      <CiCircleInfo
                        className="ms-1 pointer"
                        style={{ opacity: 0.6 }}
                        size={12}
                      />
                    </p>
                    <p
                      style={{
                        color: "#004BFF",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      ANDR
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ fontSize: "14px" }}>
                      Market cap
                      <CiCircleInfo
                        className="ms-1 pointer"
                        style={{ opacity: 0.6 }}
                        size={12}
                      />
                    </p>
                    <p
                      style={{
                        color: "#004BFF",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      $1,532,642
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ fontSize: "14px" }}>
                      Volume (24h)
                      <CiCircleInfo
                        className="ms-1 pointer"
                        style={{ opacity: 0.6 }}
                        size={12}
                      />
                    </p>
                    <p
                      style={{
                        color: "#004BFF",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      $32,642
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ fontSize: "14px" }}>
                      Circulating
                      <CiCircleInfo
                        className="ms-1 pointer"
                        style={{ opacity: 0.6 }}
                        size={12}
                      />
                    </p>
                    <p
                      style={{
                        color: "#004BFF",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      7,248 ANDR
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ fontSize: "14px" }}>
                      Total Supply
                      <CiCircleInfo
                        className="ms-1 pointer"
                        style={{ opacity: 0.6 }}
                        size={12}
                      />
                    </p>
                    <p
                      style={{
                        color: "#004BFF",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      10,000 ANDR
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ fontSize: "14px" }}>
                      Soft
                      <CiCircleInfo
                        className="ms-1 pointer"
                        style={{ opacity: 0.6 }}
                        size={12}
                      />
                    </p>
                    <p
                      style={{
                        color: "#004BFF",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      7000 ANDR
                    </p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p style={{ fontSize: "14px" }}>
                      Status
                      <CiCircleInfo
                        className="ms-1 pointer"
                        style={{ opacity: 0.6 }}
                        size={12}
                      />
                    </p>
                    <p
                      style={{
                        color: "#004BFF",
                        fontWeight: "700",
                        fontSize: "14px",
                      }}
                    >
                      On Sale
                    </p>
                  </div>
                  <hr />
                  <div>
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Official Website
                    </p>
                    <div className="btnCP justify-content-start pointer">
                      <SlGlobe
                        className="ms-2"
                        style={{
                          color: "#004BFF",
                        }}
                        size={14}
                      />
                      <p
                        className="mb-0 ms-2"
                        style={{
                          color: "#004BFF",
                          fontSize: "14px",
                        }}
                      >
                        Website
                      </p>
                    </div>
                    <br />
                    <p
                      className="mb-0"
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Socials
                    </p>
                    <div className="row">
                      <div className="col-12 col-sm-6 col-md-6">
                        <div className="btnCP justify-content-start pointer ">
                          <FaXTwitter
                            className="ms-2"
                            style={{
                              color: "#004BFF",
                            }}
                            size={14}
                          />
                          <p
                            className="mb-0 ms-2"
                            style={{
                              color: "#004BFF",
                              fontSize: "14px",
                            }}
                          >
                            X
                          </p>
                        </div>
                      </div>
                      <div className="col-12 col-sm-6 col-md-6">
                        <div className="btnCP justify-content-start pointer">
                          <FaTelegramPlane
                            className="ms-2"
                            style={{
                              color: "#004BFF",
                            }}
                            size={14}
                          />
                          <p
                            className="mb-0 ms-2"
                            style={{
                              color: "#004BFF",
                              fontSize: "14px",
                            }}
                          >
                            Telegram
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
            <div className="mb-3 col-sm-12 col-md-12 col-lg-8 col-xl-9">
              <div className="row g-3">
                <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl">
                  <div className="card-pd">
                    <p
                      className="text-center mb-3 fw-bold"
                      style={{ fontSize: "16px" }}
                    >
                      Land Area
                    </p>
                    <img
                      src={img1}
                      className="w-100 d-flex mx-auto"
                      style={{
                        maxWidth: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                    <p className="text-pd text-center mt-3 mb-0">1000 m2</p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4  col-lg-4 col-xl">
                  <div className="card-pd">
                    <p
                      className="text-center mb-3 fw-bold"
                      style={{ fontSize: "16px" }}
                    >
                      Building Area{" "}
                    </p>
                    <img
                      src={img2}
                      className="w-100 d-flex mx-auto"
                      style={{
                        maxWidth: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                    <p className="text-pd text-center mt-3 mb-0">800 m2 </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4  col-lg-4 col-xl">
                  <div className="card-pd">
                    <p
                      className="text-center mb-3 fw-bold"
                      style={{ fontSize: "16px" }}
                    >
                      Number of Unit{" "}
                    </p>
                    <img
                      src={img3}
                      className="w-100 d-flex mx-auto"
                      style={{
                        maxWidth: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                    <p className="text-pd text-center mt-3 mb-0">14 Units </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4  col-lg-4 col-xl">
                  <div className="card-pd">
                    <p
                      className="text-center mb-3 fw-bold"
                      style={{ fontSize: "16px" }}
                    >
                      Est. Unit Price{" "}
                    </p>
                    <img
                      src={img4}
                      className="w-100 d-flex mx-auto"
                      style={{
                        maxWidth: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                    <p className="text-pd text-center mt-3 mb-0">80K USDT </p>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4  col-lg-4 col-xl">
                  <div className="card-pd">
                    <p
                      className="text-center mb-3 fw-bold"
                      style={{ fontSize: "16px" }}
                    >
                      Valuation{" "}
                    </p>
                    <img
                      src={img5}
                      className="w-100 d-flex mx-auto"
                      style={{
                        maxWidth: "150px",
                        height: "150px",
                        objectFit: "contain",
                      }}
                    />
                    <p className="text-pd text-center mt-3 mb-0">900K USDT </p>
                  </div>
                </div>
              </div>
              <br />
              {/* --------------------------------- */}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-5 mb-4">
                  <div
                    className="card shadow card-lp border-0"
                    style={{ maxWidth: "unset" }}
                  >
                    <img src={map} alt="" />
                    <div className="p-4">
                      <p className="fw-bold">
                        Gg. Nusa Indah 5, Penatih Dangin Puri, Denpasar Timur,
                        Bali.{" "}
                      </p>
                      <table className="w-100 tb-pd">
                        <tr>
                          <td style={{ width: "70%" }}>
                            <p>AIRPORT, UBUD, SAFARI ZOO</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>:</p>
                          </td>
                          <td className="" style={{ width: "20%" }}>
                            <p>30 min</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "70%" }}>
                            <p>KINTAMANI</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>:</p>
                          </td>
                          <td className="" style={{ width: "20%" }}>
                            <p>75 min</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "70%" }}>
                            <p>BEDUGUL</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>:</p>
                          </td>
                          <td className="" style={{ width: "20%" }}>
                            <p>10 min</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "70%" }}>
                            <p>Bali Zoo, Bird Park, Pasar Sukowati</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>:</p>
                          </td>
                          <td className="" style={{ width: "20%" }}>
                            <p>10 min</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "70%" }}>
                            <p>Pantai Keramas, Pantai Saba</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>:</p>
                          </td>
                          <td className="" style={{ width: "20%" }}>
                            <p>20 min</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "70%" }}>
                            <p>Pantai Matahari Terbit, Pelabuhan Sanur</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>:</p>
                          </td>
                          <td className="" style={{ width: "20%" }}>
                            <p>15 min</p>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "70%" }}>
                            <p>ICON Mall, Living World, Lumintang Park</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <p>:</p>
                          </td>
                          <td className="" style={{ width: "20%" }}>
                            <p>15 min</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-7 mb-4">
                  <div
                    className="card shadow card-lp border-0 p-4"
                    style={{ maxWidth: "unset" }}
                  >
                    <p className="text-p" style={{ fontSize: "20px" }}>
                      Permits & Docs
                    </p>
                    <div className="row pt-3">
                      <div className="col-6 col-sm-3 col-md-6 col-lg-6 col-xl-3 mb-4 d-flex flex-column justify-content-start align-items-center">
                        <img
                          src={ic1}
                          className="w-100"
                          style={{ maxWidth: "120px" }}
                        />
                        <p className="fw-bold mb-0 mt-2 text-center">
                          {" "}
                          Asset Certificate
                        </p>
                      </div>
                      <div className="col-6 col-sm-3 col-md-6 col-lg-6 col-xl-3 mb-4 d-flex flex-column justify-content-start align-items-center">
                        <img
                          src={ic2}
                          className="w-100"
                          style={{ maxWidth: "120px" }}
                        />
                        <p className="fw-bold mb-0 mt-2 text-center">
                          {" "}
                          PBG Permits
                        </p>
                      </div>
                      <div className="col-6 col-sm-3 col-md-6 col-lg-6 col-xl-3 mb-4 d-flex flex-column justify-content-start align-items-center">
                        <img
                          src={ic3}
                          className="w-100"
                          style={{ maxWidth: "120px" }}
                        />
                        <p className="fw-bold mb-0 mt-2 text-center">
                          {" "}
                          Housing Permits
                        </p>
                      </div>
                      <div className="col-6 col-sm-3 col-md-6 col-lg-6 col-xl-3 mb-4 d-flex flex-column justify-content-start align-items-center">
                        <img
                          src={ic4}
                          className="w-100"
                          style={{ maxWidth: "120px" }}
                        />
                        <p className="fw-bold mb-0 mt-2 text-center">
                          {" "}
                          Appraisal Result
                        </p>
                      </div>
                    </div>
                  </div>
                  <br />
                  <div
                    className="card shadow card-lp border-0 p-4"
                    style={{ maxWidth: "unset" }}
                  >
                    <p className="text-p" style={{ fontSize: "20px" }}>
                      Project Preview{" "}
                    </p>

                    <div className="row pt-3">
                      <div className="col-6 col-sm-3 col-md-6 col-lg-6 col-xl-4 mb-4 d-flex flex-column justify-content-start align-items-center">
                        <img
                          src={imgh}
                          className="w-100"
                          style={{ borderRadius: "20px" }}
                        />
                      </div>
                      <div className="col-6 col-sm-3 col-md-6 col-lg-6 col-xl-4 mb-4 d-flex flex-column justify-content-start align-items-center">
                        <img
                          src={imgh}
                          className="w-100"
                          style={{ borderRadius: "20px" }}
                        />
                      </div>
                      <div className="col-6 col-sm-3 col-md-6 col-lg-6 col-xl-4 mb-4 d-flex flex-column justify-content-start align-items-center">
                        <img
                          src={imgh}
                          className="w-100"
                          style={{ borderRadius: "20px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <div
                    className="card shadow card-lp border-0 p-4"
                    style={{ maxWidth: "unset" }}
                  >
                    <p
                      className="text-titlep text-center"
                      style={{ fontSize: "20px" }}
                    >
                      Project Details
                    </p>
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "800",
                        }}
                      >
                        Softcap
                      </p>
                      <p
                        style={{
                          color: "#004BFF",
                          fontWeight: "800",
                          fontSize: "14px",
                          minWidth: "150px",
                        }}
                      >
                        : 8000 ANDR
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "800",
                        }}
                      >
                        Lock Time
                      </p>
                      <p
                        style={{
                          color: "#004BFF",
                          fontWeight: "800",
                          fontSize: "14px",
                          minWidth: "150px",
                        }}
                      >
                        : 365 Days
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "800",
                        }}
                      >
                        Project Status
                      </p>
                      <p
                        style={{
                          color: "#004BFF",
                          fontWeight: "800",
                          fontSize: "14px",
                          minWidth: "150px",
                        }}
                      >
                        : On Sale
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "800",
                        }}
                      >
                        Softcap
                      </p>
                      <p
                        style={{
                          color: "#004BFF",
                          fontWeight: "800",
                          fontSize: "14px",
                          minWidth: "150px",
                        }}
                      >
                        : 8000 ANDR
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "800",
                        }}
                      >
                        Project Owner
                      </p>
                      <p
                        style={{
                          color: "#004BFF",
                          fontWeight: "800",
                          fontSize: "14px",
                          minWidth: "150px",
                        }}
                      >
                        : FX Capital{" "}
                      </p>
                    </div>
                    <div className="d-flex justify-content-between">
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "800",
                        }}
                      >
                        Project Developer
                      </p>
                      <p
                        style={{
                          color: "#004BFF",
                          fontWeight: "800",
                          fontSize: "14px",
                          minWidth: "150px",
                        }}
                      >
                        : FX DEV{" "}
                      </p>
                    </div>
                    <br />
                    <p
                      className="text-center mb-0"
                      style={{ fontSize: "14px", fontWeight: "800" }}
                    >
                      Progress (20%)
                    </p>
                    <progress value="20" max="100" />
                    <div className="d-flex align-items-center justify-content-between">
                      <p
                        className="mb-0"
                        style={{ fontSize: "12px", fontWeight: "800" }}
                      >
                        20 ANDR
                      </p>
                      <p
                        className="mb-0"
                        style={{ fontSize: "12px", fontWeight: "800" }}
                      >
                        100 ANDR
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div
                    className="card shadow card-lp border-0 p-4"
                    style={{ maxWidth: "unset" }}
                  >
                    <p
                      className="text-titlep text-center"
                      style={{ fontSize: "20px" }}
                    >
                      Project Milestone
                    </p>
                    <div className="d-flex justify-content-between  align-items-center py-2 verifiedf">
                      <div className="d-flex align-items-center">
                        <FaDiamond
                          className="ms-2"
                          style={{ color: "#FF4949" }}
                        />
                        <p
                          className="mb-0 ms-2"
                          style={{ fontWeight: "600", color: "white" }}
                        >
                          Pre-Project & Planning
                        </p>
                      </div>
                      <MdVerified
                        className="me-2"
                        style={{ color: "#A74FFF" }}
                      />
                    </div>
                    <div className="d-flex justify-content-between  align-items-center py-2 verified">
                      <div className="d-flex align-items-center">
                        <FaDiamond
                          className="ms-2"
                          style={{ color: "orange" }}
                        />
                        <p
                          className="mb-0 ms-2"
                          style={{ fontWeight: "600", color: "white" }}
                        >
                          Design & Permitting
                        </p>
                      </div>
                      <MdVerified
                        className="me-2"
                        style={{ color: "#A74FFF" }}
                      />
                    </div>{" "}
                    <div className="d-flex justify-content-between  align-items-center py-2 verifiedl">
                      <div className="d-flex align-items-center">
                        <FaDiamond
                          className="ms-2"
                          style={{ color: "#FFF617" }}
                        />
                        <p
                          className="mb-0 ms-2"
                          style={{ fontWeight: "600", color: "white" }}
                        >
                          Construction Preparation
                        </p>
                      </div>
                      <MdVerified
                        className="me-2"
                        style={{ color: "#A74FFF" }}
                      />
                    </div>{" "}
                    <div className="d-flex justify-content-between  align-items-center py-2">
                      <div className="d-flex align-items-center">
                        <FaDiamond
                          className="ms-2"
                          style={{ color: "#ECFF17" }}
                        />
                        <p
                          className="mb-0 ms-2"
                          style={{ fontWeight: "600", color: "grey" }}
                        >
                          Construction
                        </p>
                      </div>
                      <FaLock className="me-2" style={{ opacity: 0.4 }} />
                    </div>{" "}
                    <div className="d-flex justify-content-between  align-items-center py-2">
                      <div className="d-flex align-items-center">
                        <FaDiamond
                          className="ms-2"
                          style={{ color: "#C3FF17" }}
                        />
                        <p
                          className="mb-0 ms-2"
                          style={{ fontWeight: "600", color: "grey" }}
                        >
                          Inspection & Finalization
                        </p>
                      </div>
                      <FaLock className="me-2" style={{ opacity: 0.4 }} />
                    </div>{" "}
                    <div className="d-flex justify-content-between  align-items-center py-2">
                      <div className="d-flex align-items-center">
                        <FaDiamond
                          className="ms-2"
                          style={{ color: "#99FF17" }}
                        />
                        <p
                          className="mb-0 ms-2"
                          style={{ fontWeight: "600", color: "grey" }}
                        >
                          Post-Construction
                        </p>
                      </div>
                      <FaLock className="me-2" style={{ opacity: 0.4 }} />
                    </div>{" "}
                    <div className="d-flex justify-content-between  align-items-center py-2">
                      <div className="d-flex align-items-center">
                        <FaDiamond
                          className="ms-2"
                          style={{ color: "#2AFF17" }}
                        />
                        <p
                          className="mb-0 ms-2"
                          style={{ fontWeight: "600", color: "grey" }}
                        >
                          Operation
                        </p>
                      </div>
                      <FaLock className="me-2" style={{ opacity: 0.4 }} />
                    </div>{" "}
                    <div className="d-flex justify-content-between  align-items-center py-2">
                      <div className="d-flex align-items-center">
                        <FaDiamond
                          className="ms-2"
                          style={{ color: "#01FFC2" }}
                        />
                        <p
                          className="mb-0 ms-2"
                          style={{ fontWeight: "600", color: "grey" }}
                        >
                          Evaluation
                        </p>
                      </div>
                      <FaLock className="me-2" style={{ opacity: 0.4 }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetail;
