import React, { useState, useEffect } from "react";
import OrderModal from "./OrderModal";
import { IoAddOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const MyOrder = () => {
  const dispatch = useDispatch();
  const [co, setCo] = useState(false);
  const [data, setData] = useState({ projects: [] }); // Inisialisasi dengan projects sebagai array

  // Fungsi untuk membuka modal
  const openCObutton = () => {
    setCo(true);
  };

  // Fungsi untuk menutup modal
  const closeCObutton = () => {
    setCo(false);
  };

  const userid = useSelector((state) => state.userId);

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `/api/user/${userid}`, // Sesuaikan dengan URL API kamu
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         },
  //       );
  //       setData(response.data); // Data pengguna
  //     } catch (error) {
  //       console.error("Error fetching user data", error);
  //     }
  //   };

  //   if (userid) {
  //     fetchUserData();
  //   }
  // }, [userid, dispatch]);

  const dummy = [
    {
      title: "sell order",
      value: "1500 ",
      status: "Funding Buyer",
      usdt: "100",
    },
  ];

  return (
    <div>
      <div className="w-100 d-flex justify-content-end">
        <button onClick={openCObutton} className="btn-filter">
          <IoAddOutline className="me-1" size={22} />
          Create Order
        </button>
      </div>
      <br />
      <div>
        <OrderModal openCO={co} closeCObutton={closeCObutton} />
        {dummy.length === 0 ? (
          <p>No transactions found.</p>
        ) : (
          <div className="row">
            {dummy.map((project, index) => (
              <div
                className="col-12 col-sm-12 col-md-6 col-xl-4 mb-3"
                key={project.id} //
              >
                <div className="d-flex cardEscrow justify-content-between ">
                  {dummy && dummy.length > 0
                    ? dummy.map((escrow, index) => (
                        <div className="d-flex  justify-content-between  w-100">
                          <div className="d-flex flex-column justify-content-between ">
                            <p
                              className="titleEscrow"
                              style={{ textTransform: "uppercase" }}
                            >
                              {escrow.title}
                            </p>
                            <p className="escrowTxAmount my-1">
                              Status : {escrow.status}
                            </p>
                            <div className="d-flex align-items-end mt-2">
                              <p className="usdtPrice">{escrow.usdt} USDT</p>
                              <p className="usdtPrice2 mb-0 ms-1"> / TA</p>
                            </div>
                          </div>

                          <div className="d-flex flex-column justify-content-between">
                            <div className="d-flex">
                              <p className="childPrice2 me-1">Value :</p>
                              <p className="childPrice">{escrow.value} TA</p>
                            </div>
                            <p className="my-2 autorelease">Auto Release</p>
                            <button className="btnBuy">Claim</button>
                          </div>
                        </div>
                      ))
                    : ""}
                </div>

                {/* Menampilkan Escrows */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyOrder;
