import React from "react";
import { IoIosArrowBack } from "react-icons/io";
import headlineImg from "../../../assets/headline.png";

import "./style.css";

import { useNavigate } from "react-router-dom";

const NewsDetail = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="">
        <div className="d-flex w-100" style={{ padding: "20px" }}>
          <div
            className="pointer d-flex align-items-center"
            onClick={() => navigate(-1)}
          >
            <IoIosArrowBack size={24} />
            <p className="mb-0 ms-2 textHead">News </p>
          </div>
        </div>
        <div className="content-dashboard container">
          <div>
            <h2 className="text-center">
              Tokenization of Property Projects : “The Future of Investment with
              Blockchain”
            </h2>
            <br />

            <div>
              <img src={headlineImg} className="imgC" />
              <br />
              <div className="d-flex justify-content-between">
                <p style={{ opacity: 0.4 }}>1 October 2024</p>
                <p style={{ opacity: 0.4 }}>30 minutes ago</p>
              </div>
              <br />
              <div>
                <p>
                  As the digital era continues to advance, the property industry
                  is undergoing a significant transformation with blockchain
                  technology. One of the latest innovations is property
                  tokenization, which allows investors from around the world to
                  own a fraction of large real estate projects using digital
                  tokens. <br /> <br /> The latest project to be tokenized is a
                  luxury apartment located in the heart of Jakarta, enabling
                  investors to purchase shares of the property in the form of
                  tokens, without the need to buy entire units. Through
                  blockchain technology, transparency and security in real
                  estate transactions are greatly enhanced. <br /> <br />{" "}
                  "Tokenization is the future of property investment. It grants
                  access to all, from small to large investors, to participate
                  in major projects in an easier and faster way," said the
                  project’s CEO. <br /> <br /> With the adoption of this
                  technology, many experts believe that the property industry
                  will become more open and inclusive, encouraging more
                  investment from various sectors of society. <br /> <br /> This
                  marks the first step toward a smarter and more secure future
                  in real estate.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetail;
