import React, { useState, useEffect } from "react";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import axios from "axios";
import "../style.css";
import Filter from "./Filter";
import SellModal from "./SellModal";

const SellList = () => {
  const [escrowTxs, setEscrowTxs] = useState([
    {
      id: 1,
      name: "0x386fcDD7e2cCEE707EFDb35674dCB896f1760d79",
      tokenChild: "20000",
      tokenParent: "10",
    },
  ]);
  const [error, setError] = useState(null);

  // const fetchEscrowTxs = async () => {
  //   try {
  //     const response = await axios.get("/api/escrowtx"); // Ganti URL sesuai backend API
  //     setEscrowTxs(response.data.data); // Setel data ke state escrowTxs
  //     setLoading(false);
  //   } catch (err) {
  //     if (err.status === 404) {
  //       setError("Nothing Transaction");
  //     } else {
  //       setError("Server Error");
  //     }
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   fetchEscrowTxs(); // Panggil fungsi untuk mengambil data saat komponen dimuat
  // }, []);

  const [co, setCo] = useState(false);

  // Fungsi untuk membuka modal
  const openCObutton = () => {
    setCo(true);
  };

  // Fungsi untuk menutup modal
  const closeCObutton = () => {
    setCo(false);
  };

  const [sm, setSM] = useState(false);
  console.log(sm);
  // Fungsi untuk membuka modal
  const openSMbutton = () => {
    setSM(true);
  };

  // Fungsi untuk menutup modal
  const closeSMbutton = () => {
    setSM(false);
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <div className="inputCO2 me-3">
          <input
            type="text"
            className="inputCOinner"
            placeholder="Type project name, project address to find your needs"
          />
        </div>
        <button className="btn-filter" onClick={openCObutton}>
          Filter
          <HiOutlineAdjustmentsHorizontal className="ms-2" />
        </button>{" "}
      </div>
      <Filter openCO={co} closeCObutton={closeCObutton} />
      <SellModal openSM={sm} closeSMbutton={closeSMbutton} />

      <br />
      {escrowTxs.length === 0 ? (
        <p>No transactions found.</p>
      ) : (
        <div className="row">
          {escrowTxs.map((tx) => (
            <div
              className="col-12 col-sm-12 col-md-6 col-xl-4 mb-3"
              key={tx.id}
            >
              <div className="d-flex cardEscrow justify-content-between">
                <div className="d-flex flex-column justify-content-between">
                  <p className="titleEscrow">
                    {`${tx.name.slice(0, 6)} .... ${tx.name.slice(-4)}`}
                  </p>
                  <p className="escrowTxAmount my-1">111 Trade Success</p>
                  <div className="d-flex align-items-end mt-2">
                    <p className="usdtPrice">{tx.tokenUSDT} USDT</p>
                    <p className=" usdtPrice2 mb-0 ms-1"> / TA</p>
                  </div>
                </div>
                <div className="d-flex flex-column justify-content-between">
                  <div className="d-flex">
                    <p className="childPrice2 me-1">Available</p>
                    <p className="childPrice">{tx.tokenChild} TA</p>
                  </div>
                  <p className="my-2 autorelease">Auto Release</p>
                  <button
                    onClick={openSMbutton}
                    className="btnBuy"
                    style={{ backgroundColor: "#FF6464" }}
                  >
                    Sell
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SellList;
