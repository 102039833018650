import React, { useState, useEffect, useRef } from "react";

const Counter = ({ target, prefix = "", suffix = "" }) => {
  const [count, setCount] = useState(0);
  const [inView, setInView] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Ketika masuk ke dalam viewport
        if (entry.isIntersecting) {
          setInView(true);
        } else {
          // Ketika keluar dari viewport
          setInView(false);
        }
      },
      { threshold: 0.1 },
    );

    // Mulai mengamati elemen
    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    // Membersihkan observer ketika komponen unmount
    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  // Mengatur animasi counter ketika inView berubah
  useEffect(() => {
    let start = 0;
    const end = parseInt(target, 10);

    // Hanya jalankan animasi jika inView true
    if (inView && start !== end) {
      const duration = 2000;
      const incrementTime = Math.abs(Math.floor(duration / end));

      const timer = setInterval(() => {
        start += 1;
        setCount(start);
        if (start === end) clearInterval(timer);
      }, incrementTime);

      // Membersihkan timer ketika komponen unmount atau ketika inView berubah
      return () => clearInterval(timer);
    }
  }, [inView, target]);

  return (
    <p className="texts1-card" ref={counterRef}>
      {prefix}
      {count.toLocaleString()}
      {suffix}
    </p>
  );
};

export default Counter;
