import React, { useState, useEffect, useRef } from "react";
import { SidebarDefi } from "../../../component/defi/SidebarDefi";
import "./dashboard.css";
import { NavbarDefi } from "../../../component/defi/NavbarDefi";
import { FaArrowRight } from "react-icons/fa";

import tfr from "./assets/tfr.svg";
import tfrc from "./assets/tfrc.svg";
import tv from "./assets/tv.svg";
import tvc from "./assets/tvc.svg";
import tpl from "./assets/tpl.svg";
import tplc from "./assets/tplc.svg";
import olp from "./assets/olp.svg";
import olpc from "./assets/olpc.svg";

import img1 from "./assets/img1.png";
import chart from "./assets/chart.png";
import { useNavigate } from "react-router-dom";
import { CiCircleInfo } from "react-icons/ci";
const Dashboard = () => {
  const navigate = useNavigate();
  const stages = [
    { title: "Pre-Project", points: "+15 Pts", color: "#FF5E5E" },
    { title: "Design & Permitting", points: "+10 Pts", color: "#FF9B5E" },
    { title: "Construction Preparation", points: "+5 Pts", color: "#FFC95E" },
    { title: "Construction", points: "+10 Pts", color: "#FFD95E" },
    { title: "Inspection", points: "+15 Pts", color: "#A5FF5E" },
    { title: "Post-Construction", points: "+10 Pts", color: "#5EFF84" },
    { title: "Operation", points: "+10 Pts", color: "#5EFFC6" },
    { title: "Evaluation", points: "+5 Pts", color: "#5EFFF8" },
  ];

  return (
    <div>
      <SidebarDefi />
      <div className="body-dashboard">
        <NavbarDefi />
        <div className="content-dashboard ">
          <div className="card-dashboard">
            <div className="row ">
              <div className="mb-4 col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="p-3 card-col">
                  <p className="mb-0 text-card">Total Fund Raises</p>
                  <p className="mb-0 text-card-2">$50 M</p>
                  <br />
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={tfr} className="card-img" />
                    <img src={tfrc} className="card-img2" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="p-3 card-col">
                  <p className="mb-0 text-card">Transaction Volume</p>
                  <p className="mb-0 text-card-2">$15000</p>
                  <br />
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={tv} className="card-img" />
                    <img src={tvc} className="card-img2" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="p-3 card-col">
                  <p className="mb-0 text-card">Total Project Launched</p>
                  <p className="mb-0 text-card-2">112</p>
                  <br />
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={tpl} className="card-img" />
                    <img src={tplc} className="card-img2" />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-3">
                <div className="p-3 card-col" style={{ border: "none" }}>
                  <p className="mb-0 text-card">On Lock Project</p>
                  <p className="mb-0 text-card-2">10</p>
                  <br />
                  <div className="d-flex align-items-center justify-content-between">
                    <img src={olp} className="card-img" />
                    <img src={olpc} className="card-img2" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br />
          {/* ------------------------------------------- */}

          <div>
            <div className="row">
              {" "}
              <div className="mb-4 col-12 col-sm-12 col-md-6 col-lg-12 col-xl-7">
                <div className="card-white p-4" style={{ height: "450px" }}>
                  <div className="d-flex mb-2 align-items-center justify-content-between">
                    <p className="text-title-s2 mb-0">
                      Project Fund Raises Progress
                    </p>
                    <FaArrowRight
                      className="pointer"
                      style={{ color: "#2575fc" }}
                      onClick={() => navigate("/fund-raises")}
                    />
                  </div>
                  <br />
                  <div className="d-flex w-100">
                    <img
                      src={img1}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "20px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="ms-3 w-100 d-flex flex-column justify-content-center">
                      <div className="d-flex align-items-end justify-content-between mb-3">
                        <div className="row w-100">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                            <p className="text-card-s2 mb-0">
                              Andromeda Project
                            </p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <p className="mb-0">(Villa Sanur)</p>
                          </div>
                        </div>
                        <p className="text-card-s2 mb-0">90%</p>
                      </div>
                      <progress style={{ marginBottom: "8px" }} value={0.9} />{" "}
                    </div>
                  </div>
                  <br />
                  <div className="d-flex w-100">
                    <img
                      src={img1}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "20px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="ms-3 w-100 d-flex flex-column justify-content-center">
                      <div className="d-flex align-items-end justify-content-between mb-3">
                        <div className="row w-100">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                            <p className="text-card-s2 mb-0">
                              Andromeda Project
                            </p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <p className="mb-0">(Villa Sanur)</p>
                          </div>
                        </div>

                        <p className="text-card-s2 mb-0">30%</p>
                      </div>
                      <progress style={{ marginBottom: "8px" }} value={0.3} />{" "}
                    </div>
                  </div>
                  <br />
                  <div className="d-flex w-100">
                    <img
                      src={img1}
                      style={{
                        width: "80px",
                        height: "80px",
                        borderRadius: "20px",
                        objectFit: "cover",
                      }}
                    />
                    <div className="ms-3 w-100 d-flex flex-column justify-content-center">
                      <div className="d-flex align-items-end justify-content-between mb-3">
                        <div className="row w-100">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6 ">
                            <p className="text-card-s2 mb-0">
                              Andromeda Project
                            </p>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <p className="mb-0">(Villa Sanur)</p>
                          </div>
                        </div>

                        <p className="text-card-s2 mb-0">40%</p>
                      </div>
                      <progress style={{ marginBottom: "8px" }} value={0.4} />{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 col-12 col-sm-12 col-md-6 col-lg-12 col-xl-5">
                <div className="card-white p-4" style={{ height: "450px" }}>
                  <p className="text-title-s2 text-center">
                    Assessment Project Index{" "}
                  </p>

                  <img
                    src={chart}
                    className="w-100 d-flex m-auto"
                    style={{ maxWidth: "350px" }}
                  />
                </div>
              </div>
            </div>
            {/* ---------- */}
            <div className="row">
              <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                <div className="card-white p-4">
                  <p className="text-title-s2 d-flex align-items-center justify-content-between">
                    Trending Project{" "}
                    <CiCircleInfo
                      className="ms-1 pointer"
                      style={{ opacity: 1 }}
                      color="grey"
                      size={16}
                    />
                  </p>
                  <br />
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="cardTP" style={{ borderRadius: "20px" }}>
                        <div className="d-flex">
                          <div className="d-flex w-100 align-items-center">
                            <img
                              src={img1}
                              className="responsive-img"
                              alt="Andromeda"
                            />
                            <p className="mb-0 ms-2 responsive-text tpT text-card-s2b">
                              Andromeda
                            </p>
                          </div>
                          <div className="w-50 d-flex flex-column justify-content-center align-items-end">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <p className="mb-0 responsive-text-m text-card-s2b">
                                Andromeda
                              </p>
                              <p
                                className="mb-0 responsive-text text-card-s2"
                                style={{ fontSize: "14px" }}
                              >
                                Vol (1D)
                              </p>
                              <p
                                className="mb-0 responsive-text text-card-s2"
                                style={{ fontSize: "14px" }}
                              >
                                10K USDT
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-3">
                      <div className="cardTP" style={{ borderRadius: "20px" }}>
                        <div className="d-flex">
                          <div className="d-flex w-100 align-items-center">
                            <img
                              src={img1}
                              className="responsive-img"
                              alt="Andromeda"
                            />
                            <p className="mb-0 ms-2 responsive-text tpT text-card-s2b">
                              Andromeda
                            </p>
                          </div>
                          <div className="w-50 d-flex flex-column justify-content-center align-items-end">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <p className="mb-0 responsive-text-m text-card-s2b">
                                Andromeda
                              </p>
                              <p
                                className="mb-0 responsive-text text-card-s2"
                                style={{ fontSize: "14px" }}
                              >
                                Vol (1D)
                              </p>
                              <p
                                className="mb-0 responsive-text text-card-s2"
                                style={{ fontSize: "14px" }}
                              >
                                10K USDT
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-3">
                      <div className="cardTP" style={{ borderRadius: "20px" }}>
                        <div className="d-flex">
                          <div className="d-flex w-100 align-items-center">
                            <img
                              src={img1}
                              className="responsive-img"
                              alt="Andromeda"
                            />
                            <p className="mb-0 ms-2 responsive-text tpT text-card-s2b">
                              Andromeda
                            </p>
                          </div>
                          <div className="w-50 d-flex flex-column justify-content-center align-items-end">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <p className="mb-0 responsive-text-m text-card-s2b">
                                Andromeda
                              </p>
                              <p
                                className="mb-0 responsive-text text-card-s2"
                                style={{ fontSize: "14px" }}
                              >
                                Vol (1D)
                              </p>
                              <p
                                className="mb-0 responsive-text text-card-s2"
                                style={{ fontSize: "14px" }}
                              >
                                10K USDT
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col col-sm-12 col-md-12 col-lg-12 col-xl-6 mb-3">
                <div className="card-white p-4">
                  <p className="text-title-s2 d-flex align-items-center justify-content-between">
                    Recently Added Project{" "}
                    <CiCircleInfo
                      className="ms-1 pointer"
                      style={{ opacity: 1 }}
                      color="grey"
                      size={16}
                    />{" "}
                  </p>
                  <br />
                  <div className="row">
                    <div className="col-12 mb-3">
                      <div className="cardTP" style={{ borderRadius: "20px" }}>
                        <div className="d-flex">
                          <div className="d-flex w-100 align-items-center">
                            <img
                              src={img1}
                              className="responsive-img"
                              alt="Andromeda"
                            />
                            <p className="mb-0 ms-2 responsive-text tpT text-card-s2b">
                              Andromeda
                            </p>
                          </div>
                          <div className="w-100 d-flex flex-column justify-content-center align-items-end">
                            <p className="mb-0 responsive-text-m text-card-s2b">
                              Andromeda
                            </p>
                            <button className="btn-connect rapRes">
                              See Project
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-3">
                      <div className="cardTP" style={{ borderRadius: "20px" }}>
                        <div className="d-flex">
                          <div className="d-flex w-100 align-items-center">
                            <img
                              src={img1}
                              className="responsive-img"
                              alt="Andromeda"
                            />
                            <p className="mb-0 ms-2 responsive-text tpT text-card-s2b">
                              Andromeda
                            </p>
                          </div>
                          <div className="w-100 d-flex flex-column justify-content-center align-items-end">
                            <p className="mb-0 responsive-text-m text-card-s2b">
                              Andromeda
                            </p>
                            <button className="btn-connect rapRes">
                              See Project
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-3">
                      <div className="cardTP" style={{ borderRadius: "20px" }}>
                        <div className="d-flex">
                          <div className="d-flex w-100 align-items-center">
                            <img
                              src={img1}
                              className="responsive-img"
                              alt="Andromeda"
                            />
                            <p className="mb-0 ms-2 responsive-text tpT text-card-s2b">
                              Andromeda
                            </p>
                          </div>
                          <div className="w-100 d-flex flex-column justify-content-center align-items-end">
                            <p className="mb-0 responsive-text-m text-card-s2b">
                              Andromeda
                            </p>
                            <button className="btn-connect rapRes">
                              See Project
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ---------- */}
            <div
              className="card-white p-4"
              style={{ position: "relative", overflowX: "scroll" }}
            >
              <p className="text-title-s2">Project Milestone</p>
              <div className="timeline-horizontal p-0">
                {stages.map((stage, index) => (
                  <div className="timeline-item mt-4" key={index}>
                    <div
                      className="timeline-point"
                      style={{ backgroundColor: stage.color }}
                    >
                      <div>{stage.points}</div>
                    </div>
                    <div className="timeline-title">{stage.title}</div>
                    {index < stages.length - 1 && (
                      <div className="timeline-line" />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
