import React, { useState, useEffect } from "react";
import "../style.css";
import "../order/modalStyle.css";
import ReactSlider from "react-slider";
import { Tab, Nav } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";

const Filter = ({ openCO, closeCObutton }) => {
  const [onactive, setOnactive] = useState("buy");
  console.log(onactive);

  const [co, setCo] = useState(openCO);

  useEffect(() => {
    setCo(openCO);
  }, [openCO]);

  const handleClose = () => {
    closeCObutton(); // Panggil fungsi menutup dari props
    setOnactive("buy");
  };

  const [selectedOption, setSelectedOption] = useState(""); // State untuk pilihan dropdown

  const options = ["Option 1", "Option 2", "Option 3"]; // Daftar pilihan untuk dropdown

  const handleSelect = (projectid, token) => {
    setSelectedOption({
      token: token,
      projectId: projectid,
    }); // Set pilihan yang dipilih
  };

  const [tokenList, setTokenList] = useState([
    {
      id: 1,
      nameToken: "Andromeda",
      tokenBalance: 100000,
      symbolToken: "TA",
    },
  ]);
  const [errorTSTamount, setErrorTSTamount] = useState("");
  const [TSTAmount, setTSTAmount] = useState("");

  const [sliderValue, setSliderValue] = useState([0, 500]);

  const handleSliderChange = (value) => {
    setSliderValue(value); // Update nilai slider
  };
  return (
    <div className="">
      {co && (
        <div className="blocker">
          <div className="ordermodal">
            <div className="ordermodalInner">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <h3 className="mb-0 fw-bold">Filter</h3>
                <IoClose onClick={handleClose} className="pointer" size={24} />
              </div>
              <p className="mb-0" style={{ opacity: "60%" }}>
                Save filter for next use
              </p>
              <hr className="mt-2" />
              <Dropdown className="my-3">
                <Dropdown.Toggle className="tokenToggle" id="dropdown-basic">
                  {selectedOption.token || "Project Token"}{" "}
                </Dropdown.Toggle>

                <Dropdown.Menu className="menudropdown">
                  {tokenList.length === 0 ? (
                    <Dropdown.Item disabled>No tokens available</Dropdown.Item>
                  ) : (
                    tokenList.map((token, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() =>
                          handleSelect(
                            token.id,
                            token.nameToken,
                            token.balance,
                            token.symbolToken,
                          )
                        }
                      >
                        {token.nameToken}
                      </Dropdown.Item>
                    ))
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <div className="contTo mb-2">
                <p
                  className="mb-0 text-end"
                  style={{ fontSize: "11px", color: "red" }}
                >
                  {errorTSTamount}
                </p>
                <div className="d-flex justify-content-between mb-1">
                  <p className="mb-0">Amount</p>
                </div>
                <div className="inputCO d-flex align-items-center">
                  <input
                    disabled={!selectedOption}
                    placeholder="e.g 100"
                    type="number"
                    className="inputCOinner"
                    value={TSTAmount}
                    onChange={(e) => setTSTAmount(e.target.value)}
                  />

                  <p className="mb-0 ms-1 fw-semibold">TA</p>
                </div>
                <div className="d-flex justify-content-between mt-3">
                  <div className="valT">10 TA</div>
                  <div className="valT">100 TA</div>
                  <div className="valT">200 TA</div>
                  <div className="valT">500 TA</div>
                  <div className="valT">1000 TA</div>
                </div>
                <br />
                <div>
                  <p className="mb-1">Price Range</p>
                  <ReactSlider
                    className="horizontal-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    value={sliderValue}
                    onChange={handleSliderChange}
                    defaultValue={[20, 500]}
                    min={0}
                    max={1000}
                    pearling
                    minDistance={100}
                    ariaLabel={["Lower thumb", "Upper thumb"]}
                    ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                    renderThumb={(props, state) => (
                      <div {...props}>{state.valueNow}</div>
                    )}
                  />
                  <div className="d-flex justify-content-between mt-3">
                    {/* Menampilkan nilai min dan max slider */}
                    <p className="mb-0" style={{ opacity: 0.5 }}>
                      Min: {sliderValue[0]} USDT
                    </p>
                    <p className="mb-0" style={{ opacity: 0.5 }}>
                      Max: {sliderValue[1]} USDT
                    </p>
                  </div>
                  <br />
                  <div className="d-flex">
                    <button
                      className="btn btn-light w-100 me-2 "
                      style={{ backgroundColor: "#F2F2F2" }}
                    >
                      Reset
                    </button>
                    <button className="btn btn-buy text-light w-100 ms-2">
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Filter;
