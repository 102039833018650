export const SET_SB_ON = "SET_SB_ON";
export const SET_PROJECTS_OPEN = "SET_PROJECTS_OPEN";
export const SET_TOKEN = "SET_TOKEN";
export const REMOVE_TOKEN = "REMOVE_TOKEN";
export const SET_USER_ID = "SET_USER_ID";
export const SET_AUTH_STATUS = "SET_AUTH_STATUS";

export const setSbOn = (isOn) => ({
  type: SET_SB_ON,
  payload: isOn,
});

export const setProjectsOpen = (isOpen) => ({
  type: SET_PROJECTS_OPEN,
  payload: isOpen,
});

export const setToken = (token) => ({
  type: SET_TOKEN,
  payload: token,
});

export const removeToken = () => ({
  type: REMOVE_TOKEN,
});

export const setUserId = (userId) => ({
  type: SET_USER_ID,
  payload: userId,
});

export const setAuthStatus = (status) => ({
  type: SET_AUTH_STATUS,
  payload: status,
});
