import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { SidebarDefi } from "../../../component/defi/SidebarDefi";
import { NavbarDefi } from "../../../component/defi/NavbarDefi";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import tick from "../../../assets/examtoken.png";
import hdline from "./assets/hdline.png";
import coin1 from "./assets/usdt.png";
import coin2 from "./assets/pol.png";

import {
  setAuthStatus,
  setSbOn,
  setToken,
  setUserId,
} from "../../../redux/action";
import "./style.css";

const ERC20_ABI = [
  {
    constant: true,
    inputs: [{ name: "_owner", type: "address" }],
    name: "balanceOf",
    outputs: [{ name: "balance", type: "uint256" }],
    type: "function",
  },
];

const TST_TOKEN_ADDRESS = "0x7A8F55659236181f8184c30983c84C8e31bd1771"; // Ganti dengan alamat kontrak TST
const USDT_TOKEN_ADDRESS = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"; // Ganti dengan alamat kontrak USDT

export function Account() {
  const [balances, setBalances] = useState({
    polBalance: 0,
    tstBalance: 0,
    usdtBalance: 0,
  });

  const dispatch = useDispatch();

  const tokenAuth = useSelector((state) => state.token);
  const userid = useSelector((state) => state.userId);
  const authStat = useSelector((state) => state.authStatus);

  // Fungsi untuk mendapatkan balance ETH, TST, dan USDT
  const getBalances = async (web3Instance, userAddress) => {
    // Ambil balance ETH
    const polBalanceWei = await web3Instance.eth.getBalance(userAddress);
    const polBalance = web3Instance.utils.fromWei(polBalanceWei, "ether");

    // Ambil balance TST
    const tstContract = new web3Instance.eth.Contract(
      ERC20_ABI,
      TST_TOKEN_ADDRESS,
    );
    const tstBalanceRaw = await tstContract.methods
      .balanceOf(userAddress)
      .call();
    const tstBalance = web3Instance.utils.fromWei(tstBalanceRaw, "ether");

    const usdtContract = new web3Instance.eth.Contract(
      ERC20_ABI,
      USDT_TOKEN_ADDRESS,
    );
    const usdtBalanceRaw = await usdtContract.methods
      .balanceOf(userAddress)
      .call();
    const usdtBalance = web3Instance.utils.fromWei(usdtBalanceRaw, "mwei");

    // Set balance di state
    setBalances({ polBalance, tstBalance, usdtBalance });
  };

  useEffect(() => {
    const cekwallet = async () => {
      if (window.ethereum) {
        try {
          const web3Instance = new Web3(window.ethereum);
          const userAccounts = await web3Instance.eth.getAccounts();

          // Dapatkan balances jika akun tersedia
          if (userAccounts.length > 0) {
            await getBalances(web3Instance, userAccounts[0]);
          }
        } catch (error) {
          console.error("Error connecting to Web3", error);
        }
      } else {
        alert("Please install MetaMask!");
      }
    };
    cekwallet();
  }, []);

  // useEffect(() => {
  //   const fetchUserData = async () => {
  //     try {
  //       const response = await axios.get(
  //         `/api/user/${userid}`, // Sesuaikan dengan URL API kamu
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${tokenAuth}`, // Menambahkan Bearer tokenAuth ke header
  //           },
  //         },
  //       );
  //       setAccountBalance(response.data.tokens);
  //       dispatch(setAuthStatus(true)); // Set status otentikasi menjadi false
  //     } catch (error) {
  //       // Cek jika error adalah 401
  //       if (error.response && error.response.status === 401) {
  //         dispatch(setAuthStatus(false)); // Set status otentikasi menjadi false
  //         console.error("Unauthorized access - user not authenticated");
  //       } else {
  //         console.error("Error fetching user data", error);
  //       }
  //     }
  //   };

  //   if (userid) {
  //     fetchUserData(); // Panggil fungsi untuk mengambil data pengguna
  //   }
  // }, [userid, tokenAuth, dispatch]);

  const wallet = [
    {
      name: "Andromeda",
      symbol: "ANDR",
      val: 12000,
      project: "Andromeda Project",
      name: "Andromeda",
      name: "Andromeda",
    },
    {
      name: "Orion",
      symbol: "ORN",
      val: 1200,
      project: "Orion Project",
      name: "Andromeda",
      name: "Andromeda",
    },
  ];
  return (
    <div>
      <SidebarDefi />

      <div className="body-dashboard">
        <NavbarDefi />
        <div className="balance-info">
          {/* <p>POL Token: {balances.polBalance} POL</p>
          <p>TST Onchain Token: {balances.tstBalance} TST</p>
          <p>USDT Token: {balances.usdtBalance} USDT</p> */}
          {/* {accountBalance.map((token, index) => (
            <div key={index}>
              <p>
                {token.name}: {token.balance} {token.symbol}
              </p>
            </div>
          ))} */}
          <div className="cardAsset">
            <p className="mb-4" style={{ fontSize: "20px" }}>
              Total Equity
            </p>
            <div className="d-flex align-items-end">
              <p style={{ fontSize: "30px", lineHeight: "28px" }}>3,630.45</p>
              <p className="ms-2" style={{ fontSize: "14px" }}>
                USDT
              </p>
            </div>
            <img src={hdline} className="cardImg" />
            <img src={coin1} className="pol" />
            <img src={coin2} className="usdt" />
          </div>
          <br />
          <div className="row">
            {wallet.map((tx) => (
              <div
                className="col-12 col-sm-12 col-md-6 col-xl-4 mb-3"
                key={tx.id}
              >
                <div className="d-flex cardEscrow justify-content-between p-3">
                  <div className="d-flex aling-items-center">
                    <img
                      src={tick}
                      className="me-3"
                      style={{ width: "60px", height: "60px" }}
                    />
                    <div className="d-flex flex-column justify-content-between">
                      <p className="titleEscrow mt-1">{tx.symbol}</p>
                      <p className="childPrice2 mb-1">{tx.name}</p>
                      {/* <div className="d-flex align-items-end mt-2">
                      <p className="usdtPrice">{tx.tokenUSDT} USDT</p>
                      <p className=" usdtPrice2 mb-0 ms-1"> / TA</p>
                    </div> */}
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-between">
                    <div className="d-flex justify-content-end mt-1">
                      <p
                        className="childPrice me-1"
                        style={{ fontSize: "20px" }}
                      >
                        1000
                      </p>
                      <p className="childPrice" style={{ fontSize: "20px" }}>
                        {tx.tokenChild} TA
                      </p>
                    </div>
                    <p className="childPrice2"> ≈ 1500 USDT</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
