import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "./navbar.css";
import logo from "../assets/logoSto.png";
import { CgMenuRight } from "react-icons/cg";
function Navbar({ activePage }) {
  const navigate = useNavigate();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobileView(true);
      } else {
        setIsMobileView(false);
        setIsMobileMenuOpen(false);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prevState) => !prevState);
  };

  const getColor = (page) => {
    return activePage === page ? "#B06DFF" : "#A6A6A6";
  };

  return (
    <div>
      <div className="navv">
        <div className="container w-100">
          <div className="d-flex align-items-center justify-content-between">
            <img src={logo} alt="" style={{ width: "55px" }} />
            {!isMobileView ? (
              <div className="d-flex align-items-center">
                <p
                  onClick={() => navigate("/")}
                  className=" textn pointer mb-0 ms-5"
                  style={{
                    caretColor: "transparent",
                    color: getColor("Home"),
                  }}
                >
                  Home
                </p>
                <p
                  onClick={() => navigate("/ecosystem")}
                  className=" textn pointer mb-0 ms-5"
                  style={{
                    caretColor: "transparent",
                    color: getColor("Ecosystem"),
                  }}
                >
                  Ecosystem
                </p>
                <div className="textn pointer mb-0 ms-5">
                  <button
                    onClick={() => navigate(`/dashboard`)}
                    className="btn-connect"
                    style={{ borderRadius: "10px" }}
                  >
                    De-Fi
                  </button>
                </div>
              </div>
            ) : (
              <div>
                <button
                  onClick={toggleMobileMenu}
                  className="btn btn-sm btn-primary d-flex align-items-center p-2"
                  style={{ backgroundColor: "#B06DFF", border: "none" }}
                >
                  <CgMenuRight size={20} />
                </button>
              </div>
            )}
          </div>
          {isMobileMenuOpen ? (
            <div className="p-3">
              <p
                onClick={() => navigate("/")}
                className=" textn pointer mb-4 mt-3"
                style={{
                  caretColor: "transparent",
                  color: getColor("Home"),
                }}
              >
                Home
              </p>{" "}
              <p
                onClick={() => navigate("/ecosystem")}
                className=" textn pointer mb-4"
                style={{
                  caretColor: "transparent",
                  color: getColor("Ecosystem"),
                }}
              >
                Ecosystem
              </p>{" "}
              <div className="textn pointer mb-0">
                <button
                  onClick={() => navigate(`/dashboard`)}
                  className="btn-connect"
                  style={{ borderRadius: "10px" }}
                >
                  De-Fi
                </button>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
