import React, { useState, useEffect } from "react";
import "../style.css";
import "./modalStyle.css";

import { Tab, Nav } from "react-bootstrap";
import { IoClose } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
import SellModal from "./sellModal";
import SellModald from "./sellModaldummy";
import BuyModald from "./buyModaldummy";

const OrderModal = ({ openCO, closeCObutton }) => {
  const [onactive, setOnactive] = useState("buy");
  console.log(onactive);

  const [co, setCo] = useState(openCO);

  useEffect(() => {
    setCo(openCO);
  }, [openCO]);

  const handleClose = () => {
    closeCObutton(); // Panggil fungsi menutup dari props
    setOnactive("buy");
  };

  const [selectedOption, setSelectedOption] = useState(""); // State untuk pilihan dropdown

  const options = ["Option 1", "Option 2", "Option 3"]; // Daftar pilihan untuk dropdown

  const handleSelect = (option) => {
    setSelectedOption(option); // Set pilihan yang dipilih
  };

  return (
    <div className="">
      {co && (
        <div className="blocker">
          <div className="ordermodal">
            <div className="ordermodalInner">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h3 className="mb-0 fw-bold">Create Order</h3>
                <IoClose onClick={handleClose} className="pointer" size={24} />
              </div>
              <Tab.Container defaultActiveKey="buy">
                <Nav variant="tabs" style={{ border: "none" }}>
                  <Nav.Item
                    className="d-flex w-50 sellNl"
                    onClick={() => setOnactive("buy")}
                  >
                    <Nav.Link className="btnCO" eventKey="buy">
                      <div
                        className={`activeBuy
                          ${onactive === "buy" ? "activeBuy" : "inactiveBuy"}
                        `}
                      >
                        Buy
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item
                    className="d-flex w-50 buyNl"
                    onClick={() => setOnactive("sell")}
                  >
                    <Nav.Link className="btnCO" eventKey="sell">
                      <div
                        className={`activeSell
                            ${
                              onactive === "sell"
                                ? "activeSell"
                                : "inactiveSell"
                            }
                          `}
                      >
                        Sell
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="sell">
                    <SellModald />
                  </Tab.Pane>
                  <Tab.Pane eventKey="buy">
                    <BuyModald />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderModal;
