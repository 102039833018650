// reducer.js
import {
  SET_SB_ON,
  SET_PROJECTS_OPEN,
  SET_TOKEN,
  REMOVE_TOKEN,
  SET_USER_ID,
  SET_AUTH_STATUS,
} from "./action";

const initialState = {
  sbOn: false,
  isProjectsOpen: false,
  token: null, // Tambahkan state untuk token
  authStatus: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SB_ON:
      return {
        ...state,
        sbOn: action.payload,
      };
    case SET_PROJECTS_OPEN:
      return {
        ...state,
        isProjectsOpen: action.payload,
      };
    case SET_TOKEN: // Handler untuk token
      return {
        ...state,
        token: action.payload,
      };
    case REMOVE_TOKEN: // Handler untuk menghapus token
      return {
        ...state,
        token: null,
      };
    case SET_USER_ID: // Tambahkan handler untuk userId
      return {
        ...state,
        userId: action.payload,
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        authStatus: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
