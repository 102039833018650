import React, { useEffect, useState } from "react";
import "./sidebar.css";
import { CgMenuRight } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import {
  setAuthStatus,
  setSbOn,
  setToken,
  setUserId,
} from "../../redux/action";
import Web3 from "web3";
import axios from "axios";
import { useLocation } from "react-router-dom"; // Import useLocation

export function NavbarDefi({}) {
  const dispatch = useDispatch();
  const sbOn = useSelector((state) => state.sbOn);
  const tokenAuth = useSelector((state) => state.token);
  const userid = useSelector((state) => state.userId);
  const authStat = useSelector((state) => state.authStatus);
  const location = useLocation(); // Get the current location

  const [account, setAccount] = useState([]);

  const opensb = () => {
    dispatch(setSbOn(true));
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        dispatch(setSbOn(true));
      } else {
        dispatch(setSbOn(false));
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  const initWeb3 = async () => {
    if (window.ethereum) {
      try {
        const web3Instance = new Web3(window.ethereum);
        await window.ethereum.request({ method: "eth_requestAccounts" });

        const userAccounts = await web3Instance.eth.getAccounts();

        setAccount(userAccounts);

        const response = await axios.post(
          `/api/auth`, // Adjust this URL as needed
          { address: userAccounts[0] },
          {
            headers: {
              "Content-Type": "application/json",
            },
          },
        );
        dispatch(setToken(response.data.token));
        dispatch(setUserId(response.data.user.id));
      } catch (error) {
        console.error("Error connecting to Web3", error);
      }
    } else {
      alert("Please install MetaMask!");
    }
  };

  useEffect(() => {
    const cekwallet = async () => {
      if (window.ethereum) {
        try {
          const web3Instance = new Web3(window.ethereum);
          const userAccounts = await web3Instance.eth.getAccounts();
          setAccount(userAccounts);
        } catch (error) {
          console.error("Error connecting to Web3", error);
        }
      } else {
        alert("Please install MetaMask!");
      }
    };
    cekwallet();
  }, []);

  // Function to determine the title based on the current path
  const getTitle = () => {
    switch (location.pathname) {
      case "/dashboard":
        return "Dashboard";
      case "/project":
        return "Launchpad";
      case "/escrow":
        return "Escrow Market";
      case "/buyback":
        return "BuyBack Event";
      case "/news":
        return "News";
      case "/account":
        return "Your Asset";
      default:
        return "Default Title"; // Fallback title
    }
  };

  return (
    <div className="nav-body">
      <div>
        <p className="mb-0 title-nav">{sbOn ? getTitle() : null}</p>
        {!sbOn && (
          <CgMenuRight onClick={opensb} size={20} className="mb-1 menu" />
        )}
      </div>
      <button className="btn-connect" onClick={initWeb3}>
        {account.length === 0 || !authStat
          ? "Connect Wallet"
          : account[0].length > 20
          ? `${account[0].slice(0, 6)} .... ${account[0].slice(-4)}`
          : account[0]}
      </button>
    </div>
  );
}
