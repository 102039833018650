import React, { useState, useEffect } from "react"; // Ensure useState and useEffect are imported
import { useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import { useDispatch, useSelector } from "react-redux"; // Import useDispatch and useSelector for Redux
import { setSbOn, setProjectsOpen } from "../../redux/action"; // Ensure action creators are imported
import { useLocation } from "react-router-dom"; // Import useLocation for path checking
import "./sidebar.css"; // Import your CSS for styling
import { LuLayoutDashboard } from "react-icons/lu"; // Import icons
import { GoRocket } from "react-icons/go";
import { RiP2pFill } from "react-icons/ri";
import { MdCurrencyExchange } from "react-icons/md";
import { IoNewspaperOutline } from "react-icons/io5";
import { BsDatabaseLock } from "react-icons/bs";
import logo from "../../assets/logoSto.png"; // Ensure your logo path is correct
import { CgMenuRight } from "react-icons/cg"; // Import menu icon

export function SidebarDefi() {
  const navigate = useNavigate(); // Initialize navigation
  const dispatch = useDispatch(); // Initialize dispatch
  const sbOn = useSelector((state) => state.sbOn); // Get sidebar state from Redux
  const isProjectsOpen = useSelector((state) => state.isProjectsOpen); // Get project state from Redux
  const location = useLocation(); // Get current location for active tab detection

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 992) {
        dispatch(setSbOn(true)); // Show sidebar on larger screens
      } else {
        dispatch(setSbOn(false)); // Hide sidebar on smaller screens
      }
    };
    handleResize(); // Run on initial mount
    window.addEventListener("resize", handleResize); // Add resize event listener
    return () => {
      window.removeEventListener("resize", handleResize); // Cleanup listener on unmount
    };
  }, [dispatch]); // Include dispatch in the dependency array

  const closesb = () => {
    dispatch(setSbOn(false)); // Function to close the sidebar
  };

  const toggleProjects = () => {
    dispatch(setProjectsOpen(!isProjectsOpen)); // Toggle projects open state
  };

  const isActive = (path) => location.pathname === path; // Check if path is active

  return (
    <div>
      {sbOn && (
        <div className="sb-body">
          <div>
            <div className="sb-itemgroup">
              <div className="sb-item justify-content-between mt-0">
                <img
                  src={logo}
                  style={{ width: "80px", marginBottom: "10px" }}
                  alt="Logo"
                  onClick={() => navigate("/")}
                />
                <CgMenuRight
                  onClick={closesb}
                  size={20}
                  className="mb-1 menu"
                />
              </div>
              <div
                className={`sb-item ${
                  isActive("/dashboard") ? "active-tab" : ""
                }`}
                onClick={() => navigate(`/dashboard`)}
              >
                <LuLayoutDashboard size={20} className="mb-1" />
                <p className="ms-2">Dashboard</p>
              </div>
              <div
                className={`sb-item ${
                  isActive("/project") ? "active-tab" : ""
                }`}
                onClick={() => navigate(`/project`)}
              >
                <GoRocket
                  size={20}
                  className={`mb-1 ${isProjectsOpen ? "open" : ""}`}
                />
                <p className="ms-2">Launchpad</p>
              </div>
              <div
                className={`sb-item ${isActive("/escrow") ? "active-tab" : ""}`}
                onClick={() => navigate(`/escrow`)}
              >
                <RiP2pFill size={20} className="mb-1" />
                <p className="ms-2">Escrow Market</p>
              </div>
              <div
                className={`sb-item ${
                  isActive("/buyback") ? "active-tab" : ""
                }`}
                onClick={() => navigate(`/buyback`)}
              >
                <MdCurrencyExchange size={20} className="mb-1" />
                <p className="ms-2">BuyBack Event</p>
              </div>
              <div
                className={`sb-item ${isActive("/news") ? "active-tab" : ""}`}
                onClick={() => navigate(`/news`)}
              >
                <IoNewspaperOutline size={20} className="mb-1" />
                <p className="ms-2">News</p>
              </div>
              <div
                className={`sb-item ${
                  isActive("/account") ? "active-tab" : ""
                }`}
                onClick={() => navigate(`/account`)}
              >
                <BsDatabaseLock size={20} className="mb-1" />
                <p className="ms-2">Your Asset</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
